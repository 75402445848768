import React from "react";

export default (props) => (
  <div
    class={`${props.responsive ? "table-responsive" : ""}`}
    style={{
      display: "flex",
      overflowX: "auto",
      maxHeight: "70vh	",
      overflowY: "scroll",
    }}
  >
    <table className="table table-hover">{props.children}</table>
  </div>
);
