import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import Usuario from '../usuario/usuario';
import Cliente from '../cliente/cliente';
import Embalagem from '../embalagem/embalagem';
import EmbalagemConsulta from '../embalagem/embalagemConsulta';
import Fornecedor from '../fornecedor/fornecedor';
import FornecedorConsulta from '../fornecedor/fornecedorConsulta';
import FornecedorEmbalagem from '../fornecedorEmbalagem/fornecedorEmbalagem';
import EmbalagemEntrada from '../embalagemEntrada/embalagemEntrada';
import EmbalagemSaidaMotivo from '../embalagemSaidaMotivo/embalagemSaidaMotivo';
import EmbalagemSaida from '../embalagemSaida/embalagemSaida';
import EmbalagemAvariada from '../embalagemAvariada/embalagemAvariada';
import EmbalagemViagem from '../embalagemViagem/embalagemViagem';
import EmbalagemViagemComprovante from '../embalagemViagem/embalagemViagemComprovante';
import EmbalagemViagemRelatorio from '../embalagemViagemRelatorio/embalagemViagemRelatorio';
import EmbalagemViagemRelatorioSaldo from '../embalagemViagemRelatorioSaldo/embalagemViagemRelatorioSaldo';
import EmbalagemInventario from '../embalagemInventario/embalagemInventario';
import Inventario from '../inventario/inventario';
import Acumulado from '../acumulado/acumulado';
import Transportadora from '../transportadora/transportadora'
import DashboardInventarioLetsara from '../dashboardInventarioLetsara/dashboardInventarioLetsara';
import DashboardInventarioFornecedor from '../dashboardInventarioFornecedor/dashboardInventarioFornecedor';

export default props => (
	<div id='id-content-wrapper' className='content-wrapper' >
		<Switch>
			<Route path='/dashboard1' component={EmbalagemViagemRelatorioSaldo} />
			<Route path='/usuario' component={Usuario} />
			<Route path='/cliente' component={Cliente} />
			<Route path='/embalagem' component={Embalagem} />
			<Route path='/embalagemConsulta' component={EmbalagemConsulta} />
			<Route path='/fornecedor' component={Fornecedor} />
			<Route path='/fornecedorConsulta' component={FornecedorConsulta} />
			<Route path='/fornecedorEmbalagem' component={FornecedorEmbalagem} />
			<Route path='/embalagemEntrada' component={EmbalagemEntrada} />
			<Route path='/embalagemSaidaMotivo' component={EmbalagemSaidaMotivo} />
			<Route path='/embalagemSaida' component={EmbalagemSaida} />
			<Route path='/embalagemAvariada' component={EmbalagemAvariada} />
			<Route path='/embalagemViagem' component={EmbalagemViagem} />
			<Route path='/embalagemViagemComprovante' component={EmbalagemViagemComprovante} />
			<Route path='/embalagemViagemRelatorio' component={EmbalagemViagemRelatorio} />
			<Route path='/embalagemInventario' component={EmbalagemInventario} />
			<Route path='/inventario' component={Inventario} />
			<Route path='/acumulado' component={Acumulado} />
			<Route path='/transportadora' component={Transportadora}/>
			<Route path='/dashboardInventarioLetsara' component={DashboardInventarioLetsara}/>
			<Route path='/dashboardInventarioFornecedor' component={DashboardInventarioFornecedor}/>
			<Redirect from='*' to='/dashboard1' />
		</Switch>
	</div>
);
