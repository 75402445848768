import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	aguardando: false,
	registro: {},
	modoTela: 'lista',
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
	},
	lista: [],
	listaTransportadora: [],
	listaFornecedor: [],
	listaEmbalagem: [],
	listaEstoqueLocal: [],
	modoTelaItem: 'lista',
	listaItem: [],
	listaHistorico: [],
	modoTelaEvento: 'lista'
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'INVENTARIO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'INVENTARIO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'INVENTARIO_FILTRO':
            return { ...state,
				filtro: action.payload.filtro
			};

        case 'INVENTARIO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        case 'INVENTARIO_FORNECEDOR_SELECT_LISTADO':
			return {
				...state,
				listaFornecedor: action.payload.data
			};

        case 'INVENTARIO_EMBALAGEM_SELECT_LISTADO':
			return {
				...state,
				listaEmbalagem: action.payload.data
			};

        case 'INVENTARIO_ESTOQUE_LOCAL_SELECT_LISTADO':
			return {
				...state,
				listaEstoqueLocal: action.payload.data
			};

        case 'INVENTARIO_ITEM_MODO_TELA':
			return { ...state,
				modoTelaItem: action.payload.modo
			};

        case 'INVENTARIO_ITEM_LISTADO':
			return {
				...state,
				listaItem: action.payload.data
			};

        case 'INVENTARIO_HISTORICO_LISTADO':
			return {
				...state,
				listaHistorico: action.payload.data
			};

        default:
            return state;
    }
}
