import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';

import ReactExport from 'react-export-excel-fixed-xlsx';

import { setFiltro, getLista, getListaViagem } from './embalagemViagemRelatorioSaldoActions';
import { getLista as getListaCliente } from '../cliente/clienteActions';

class EmbalagemViagemRelatorioSaldo extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null,
		status_verde: true,
		status_vermelho: true
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaViagem();
    }

    render() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let lista = this.props.lista.filter(item => {
			if (this.state.pesquisar
				&& !(`${item.nome_embalagem || ''} ${item.nome_fornecedor || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {

				return false;
			}

			if (this.props.filtro.id_tipo_embalagem && item.id_tipo_embalagem != this.props.filtro.id_tipo_embalagem) {
				return false;
			}

			return true;
		}).map((item, i) => {
			
				let quantidadeDevolver = parseInt(item.acumulado_rumo_sul || 0) 
										+ parseInt(item.quantidade_rumo_sul || 0) 
										- parseInt(item.acumulado_rumo_norte || 0);

				let quantidadeFornecedor = parseInt(item.quantidade_embalagem || 0) 
										- parseInt(item.acumulado_rumo_sul || 0) 
										- parseInt(item.quantidade_rumo_sul || 0) 
										+ parseInt(item.acumulado_rumo_norte || 0);
				
				let quantidadeGMA = parseInt(item.quantidade_embalagem || 0)
									- quantidadeFornecedor
									- parseInt(item.quantidade_rumo_sul || 0)
									- parseInt(item.quantidade_rumo_norte || 0);

				let totalFluxo = quantidadeFornecedor 
								+ parseInt(item.quantidade_rumo_sul || 0) 
								+ parseInt(item.quantidade_rumo_norte || 0) 
								+ quantidadeGMA 
								- parseInt(item.quantidade_avariada || 0);

				let variacaoFluxo = parseInt(totalFluxo > 0 ? (parseInt(item.quantidade_embalagem || 0) * 100) / totalFluxo : 0);
				let statusIdeal = parseInt(item.quantidade_ideal > 0 ? (parseInt(quantidadeFornecedor || 0) * 100) / item.quantidade_ideal : 0) - 100;

				return {
					...item,
					quantidade_devolver: quantidadeDevolver,
					quantidade_fornecedor: quantidadeFornecedor,
					quantidade_gma: quantidadeGMA,
					total_fluxo: totalFluxo,
					variacao_fluxo: variacaoFluxo,
					status_ideal: statusIdeal
				};

		}).filter(item => {

			if (this.state.status_verde && this.state.status_vermelho) {
				return true;
			} else if (this.state.status_verde) {
				return item.status_ideal >= 0 || item.status_fornecedor >= 0;
			} else if (this.state.status_vermelho) {
				return item.status_ideal < 0 || item.status_fornecedor < 0;
			} else {
				return false;
			}

		});

        return (
            <div>

				<Aguardando aguardando={this.props.aguardando} />

				{this.state.id_fornecedor_selecionado ? this.modalViagens() : null}

                <Content>
					<ContentCard>
						<ContentCardHeader>
							<Row>
								<Grid cols='6 6 4 2'>
									<Button
										text='Atualizar'
										type={'primary'}
										icon={'fa fa-plus'}
										event={() => {
											this.props.getLista();
											this.props.getListaViagem();
										}} />
								</Grid>
								<Grid cols='6 6 4 2'>
									<ExcelFile
										filename={'Dashboard'}
										element={
											<Button
												text='Exportar'
												type={'primary'}
												icon={'fa fa-plus'} />
										}>
										<ExcelSheet
											data={lista.map(item => ({
												...item,
												status_ideal: `${item.status_ideal}%`,
												variacao_fluxo: `${item.variacao_fluxo}%`,
												quantidade_embalagem: parseInt(item.quantidade_embalagem || 0),
												quantidade_fornecedor: parseInt(item.quantidade_fornecedor || 0),
												quantidade_devolver: parseInt(item.quantidade_devolver || 0),
												quantidade_ideal: parseInt(item.quantidade_ideal || 0),
												quantidade_rumo_sul: parseInt(item.quantidade_rumo_sul || 0),
												quantidade_rumo_norte: parseInt(item.quantidade_rumo_norte || 0),
												saldo_cliente: parseInt(item.saldo_cliente || 0),
												quantidade_avariada: parseInt(item.quantidade_avariada || 0),
												quantidade_gma: parseInt(item.quantidade_gma || 0),
												total_fluxo: parseInt(item.total_fluxo || 0)
											}))}

											name='Dashboard'>
											<ExcelColumn label='Embalagem' value='nome_embalagem' />
											<ExcelColumn label='Fornecedor' value='nome_fornecedor' />
											<ExcelColumn label='Qtd. Adquirida' value='quantidade_embalagem' />
											<ExcelColumn label='Saldo Fluxo RS X RN' value='quantidade_devolver' />
											<ExcelColumn label='Qtd. Fornecedor' value='quantidade_fornecedor' />
											<ExcelColumn label='Qtd. Ideal/GC16' value='quantidade_ideal' />
											<ExcelColumn label='Status' value='status_ideal' />
											<ExcelColumn label='Trânsito RS' value='quantidade_rumo_sul' />
											<ExcelColumn label='Trânsito RN' value='quantidade_rumo_norte' />
											<ExcelColumn label='Qtd. GMA' value='quantidade_gma' />
											<ExcelColumn label='Qtd. Avariada' value='quantidade_avariada' />
											<ExcelColumn label='Total no Fluxo' value='total_fluxo' />
											<ExcelColumn label='Variação Qtd. Adq. X Total Fluxo' value='variacao_fluxo' />
										</ExcelSheet>
									</ExcelFile>
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Row>
								<Select
									options={this.props.listaEmbalagemTipo}
									label='Tipo de Embalagem'
									cols='12 12 3 4'
									placeholder='Selecione o tipo'
									value={this.props.filtro.id_tipo_embalagem}
									onChange={data => {
										this.props.setFiltro({ ...this.props.filtro, id_tipo_embalagem: data });
									}} />
							</Row>

							<Row>
								<LabelAndCheckbox
									name='status_verde'
									label='Status (Verde)'
									cols='6 4 3 2'
									value={this.state.status_verde}
									onChange={(data) => {
										this.setState({ ...this.state, status_verde: !this.state.status_verde });
									}} />

								<LabelAndCheckbox
									name='status_vermelho'
									label='Status (Vermelho)'
									cols='6 4 3 2'
									value={this.state.status_vermelho}
									onChange={(data) => {
										this.setState({ ...this.state, status_vermelho: !this.state.status_vermelho });
									}} />
							</Row>

							<input className='form-control'
								 placeholder='Pesquisar'
								 type='text'
								 value={this.state.pesquisar}
								 onChange={e => {
									 this.setState({ ...this.state, pesquisar: e.target.value });
								 }} />

							<Table responsive>
								<THead>
									<Tr>
										<Th backgroundColor={'#ffffff'} width={500}>Embalagem</Th>
										<Th backgroundColor={'#ffffff'} width={500}>Fornecedor</Th>
										<Th alignCenter backgroundColor={'#eceff1'} width={100}>Qtd. Adquirida</Th>
										<Th alignCenter backgroundColor={'#eceff1'} width={100}>Saldo Fluxo RS X RN</Th>
										<Th alignCenter backgroundColor={'#eceff1'} width={100}>Qtd. Fornecedor</Th>
										<Th alignCenter backgroundColor={'#eceff1'} width={100}>Qtd. Ideal/GC16</Th>
										<Th alignCenter backgroundColor={'#eceff1'} width={100}>Status</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={100}>Trânsito RS</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={100}>Trânsito RN</Th>
										<Th alignCenter backgroundColor={'#eceff1'} width={100}>Qtd. GMA</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={100}>Qtd. Avariada</Th>
										<Th alignCenter backgroundColor={'#eceff1'} width={100}>Total no Fluxo</Th>
										<Th alignCenter backgroundColor={'#eceff1'} width={100}>Variação Qtd. Adq. X Total Fluxo</Th>
									</Tr>
								</THead>
								<TBody>
									{lista.map((item, i) => {

										return (
											<Tr key={i} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
												onClick={() => this.setState({
													...this.state,
													linhaSelecionada: item.id,
													id_fornecedor_selecionado: item.id_fornecedor,
													nome_fornecedor_selecionado: item.nome_fornecedor,
													id_embalagem_selecionada: item.id_embalagem
												})}
												onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
												<Td backgroundColor={'#ffffff'} width={500}>{item.nome_embalagem}</Td>
												<Td backgroundColor={'#ffffff'} width={500}>{item.nome_fornecedor}</Td>
												<Td alignCenter backgroundColor={'#eceff1'} width={100}>{parseInt(item.quantidade_embalagem || 0)}</Td>
												<Td alignCenter backgroundColor={'#eceff1'} width={100}>{parseInt(item.quantidade_devolver || 0)}</Td>
												<Td alignCenter backgroundColor={'#eceff1'} width={100}>{parseInt(item.quantidade_fornecedor || 0)}</Td>
												<Td alignCenter backgroundColor={'#eceff1'} width={100}>{parseInt(item.quantidade_ideal || 0)}</Td>
												{/* essa */}
												<Td alignCenter backgroundColor={item.status_ideal < 0 ? '#ff8a80' : '#69f0ae'} width={100}>{item.status_ideal} %</Td>
												<Td alignCenter backgroundColor={'#ffffff'} width={100}>{parseInt(item.quantidade_rumo_sul || 0)}</Td>
												<Td alignCenter backgroundColor={'#ffffff'} width={100}>{parseInt(item.quantidade_rumo_norte || 0)}</Td>
												<Td alignCenter backgroundColor={'#eceff1'} width={100}>{parseInt(item.quantidade_gma || 0)}</Td>
												<Td alignCenter backgroundColor={'#ffffff'} width={100}>{parseInt(item.quantidade_avariada || 0)}</Td>
												<Td alignCenter backgroundColor={'#eceff1'} width={100}>{parseInt(item.total_fluxo || 0)}</Td>
												{/* essa */}
												<Td alignCenter backgroundColor={item.variacao_fluxo < 0 ? '#ff8a80' : '#69f0ae'} width={100}>{item.variacao_fluxo} %</Td>
											</Tr>
										);
									})}
								</TBody>
							</Table>
						</ContentCardBody>
					</ContentCard>
				</Content>
			</div>
		);
	}

	modalViagens() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>{this.state.nome_fornecedor_selecionado} - Viagens Abertas</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, id_fornecedor_selecionado: null, id_embalagem_selecionada: null })}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>
							<Table responsive>
								<THead>
									<Tr>
										<Th>Despacho</Th>
										<Th>Transportadora</Th>
										<Th>Viagem</Th>
										<Th>Solicitação</Th>
										<Th>Fatura</Th>
										<Th>Conhec. Transp.</Th>
										<Th>Cavalo</Th>
										<Th>Carretas</Th>
										<Th>Origem</Th>
										<Th>Destino</Th>
										<Th>Embalagem</Th>
										<Th alignCenter>Quantidade</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaViagem.filter(item =>
										item.id_status == 1
										&& item.id_embalagem == this.state.id_embalagem_selecionada
										&& (item.id_fornecedor_origem == this.state.id_fornecedor_selecionado
										|| item.id_fornecedor_destino == this.state.id_fornecedor_selecionado)
									).map(item => (
										<Tr key={item.id}>
											<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem)}</Td>
											<Td>{item.nome_transportadora}</Td>
											<Td>{item.viagem}</Td>
											<Td>{item.solicitacao}</Td>
											<Td>{item.fatura}</Td>
											<Td>{item.conhecimento_transporte || ''}</Td>
											<Td>{item.cavalo || ''}</Td>
											<Td>{item.carreta1 || ''}{item.carreta2 ? ', ' : ''}{item.carreta2 || ''}</Td>
											<Td>{item.nome_origem == 'Cliente' ? item.nome_cliente : item.nome_fornecedor_origem}</Td>
											<Td>{item.nome_destino == 'Cliente' ? item.nome_cliente : item.nome_fornecedor_destino}</Td>
											<Td>{item.nome_embalagem}</Td>
											<Td alignCenter>{parseInt(item.quantidade)}</Td>
										</Tr>
									))}
								</TBody>
							</Table>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => this.setState({ ...this.state, id_fornecedor_selecionado: null })}>OK</button>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	filtro: state.embalagemViagemRelatorioSaldo.filtro,
	aguardando: state.embalagemViagemRelatorioSaldo.aguardando,
	lista: state.embalagemViagemRelatorioSaldo.lista,
	listaViagem: state.embalagemViagemRelatorioSaldo.listaViagem,
	listaEventoTipo: state.embalagemViagemRelatorioSaldo.listaEventoTipo,
	listaEmbalagemTipo: state.embalagem.listaTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setFiltro, getLista, getListaViagem
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EmbalagemViagemRelatorioSaldo);
