const INITIAL_STATE = {
	aguardando: false,
	listaSaldo: [],
	listaViagem: []
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {

		case 'ACUMULADO_SET_AGUARDANDO':
			return { ...state,
			aguardando: action.payload
		};

		case 'ACUMULADO_SALDO_LISTADO':
            return {
				...state,
				listaSaldo: action.payload.data
			};

		case 'ACUMULADO_EMBALAGEM_VIAGEM_LISTADO':
			return {
				...state,
				listaViagem: action.payload.data
			};

        default:
            return state;
    }
}
