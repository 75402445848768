import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';

import { setModoTela, initForm } from './usuarioActions';

class UsuarioForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome *' placeholder='Informe o Nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							{!this.props.formularioValues.id_transportadora && !this.props.formularioValues.id_fornecedor ? (
								<Select
									name='id_cliente'
									options={this.props.listaCliente}
									label='Acesso Perfil Operação'
									cols='12 12 12 12'
									placeholder='Selecione a operação'
									readOnly={readOnly}
									value={this.props.formularioValues.id_cliente}
									onChange={data => {
										if (data) {
											this.props.initForm({
												...this.props.formularioValues,
												id_cliente: data
											});
										}
									}} />
							) : null}

							{!this.props.formularioValues.id_cliente && !this.props.formularioValues.id_fornecedor ? (
								<Select
									name='id_transportadora'
									options={this.props.listaTransportadora}
									label='Acesso Perfil Transportadora'
									cols='12 12 12 12'
									placeholder='Selecione a transportadora'
									readOnly={readOnly}
									value={this.props.formularioValues.id_transportadora}
									onChange={data => {
										if (data) {
											this.props.initForm({
												...this.props.formularioValues,
												id_transportadora: data
											});
										}
									}} />
							) : null}

							{!this.props.formularioValues.id_cliente && !this.props.formularioValues.id_transportadora ? (
								<Select
									name='id_fornecedor'
									options={this.props.listaFornecedor}
									label='Acesso Perfil Fornecedor'
									cols='12 12 12 12'
									placeholder='Selecione o fornecedor'
									readOnly={readOnly}
									value={this.props.formularioValues.id_fornecedor}
									onChange={data => {
										if (data) {
											this.props.initForm({
												...this.props.formularioValues,
												id_fornecedor: data
											});
										}
									}} />
							) : null}

							<Field
								name='login'
								component={LabelAndInput}
								label='Login *'
								placeholder='Informe o Usuário'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='senha'
								component={LabelAndInput}
								type="password"
								label='Senha *'
								placeholder='Informe a Senha'
								cols='12 12 12 12'
								readOnly={readOnly} />
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>

				</Form>
			</ContentCard>
        )
    }


}

UsuarioForm = reduxForm({form: 'usuarioForm', destroyOnUnmount: false})(UsuarioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('usuarioForm')(state),
	registro: state.usuario.registro,
	listaCliente: state.usuario.listaCliente,
	listaTransportadora: state.usuario.listaTransportadora,
	listaFornecedor: state.usuario.listaFornecedor
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioForm);
