const INITIAL_STATE = {
	aguardando: false,
	filtro: {
		aberta: true,
		fechada: false,
		sentido_fornecedor_cliente: true,
		sentido_cliente_fornecedor: true,
		id_evento: null
	},
	lista: [],
	listaViagem: [],
	listaEventoTipo: [{ id: 1, valor: 'Chegada para Carga' }, { id: 2, valor: 'Início de Viagem' }, { id: 3, valor: 'Chegada na Fronteira' }, { id: 4, valor: 'Saída da Fronteira' }, { id: 5, valor: 'Chegada para Descarga' }, { id: 6, valor: 'Fim de Descarga' }]
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

		case 'EMBALAGEM_VIAGEM_RELATORIO_SALDO_FILTRO':
			return { ...state,
				filtro: action.payload.filtro
			};

		case 'EMBALAGEM_VIAGEM_RELATORIO_SALDO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'EMBALAGEM_VIAGEM_RELATORIO_SALDO_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'EMBALAGEM_VIAGEM_RELATORIO_SALDO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

		case 'DASHBOARD_EMBALAGEM_VIAGEM_RELATORIO_SALDO_VIAGEM_LISTADO':
			return {
				...state,
				listaViagem: action.payload.data
			};

        default:
            return state;
    }
}
