import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import { setError } from '../common/toast/toast';

import {
	setModoTela, initForm
} from './embalagemInventarioActions';

class EmbalagemInventarioForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>

							<Field
								name='datahora_previsao'
								component={LabelAndInputMask}
								label='Data/Hora Previsão' placeholder='Informe a data e hora'
								cols='12 6 4 4'
								mask='99/99/9999 99:99'
								readOnly={readOnly} />

							{this.props.formularioValues && this.props.formularioValues.id ? (
								<Field
									name='datahora_inventario'
									component={LabelAndInputMask}
									label='Data/Hora Realização' placeholder='Informe a data e hora'
									cols='12 6 4 4'
									mask='99/99/9999 99:99'
									readOnly={readOnly} />
							) : null}

							<Select
								name='id_estoque_local'
								options={this.props.listaEstoqueLocal}
								label='Local'
								cols='12 12 6 6'
								placeholder='Selecione o local'
								readOnly={readOnly}
								value={this.props.formularioValues.id_estoque_local}
								onChange={data => {
									if (data) {
										this.props.initForm({
											...this.props.formularioValues,
											id_estoque_local: data,
											id_fornecedor_origem: null
										});
									}
								}} />

							{/*Fornecedor*/}
							{this.props.formularioValues.id_estoque_local == 2 ? (
								<Select
									name='id_fornecedor'
									options={this.props.listaFornecedor}
									label='Fornecedor'
									cols='12 12 12 12'
									placeholder='Selecione o fornecedor'
									readOnly={readOnly}
									value={this.props.formularioValues.id_fornecedor}
									onChange={data => {
										if (data) {
											this.props.initForm({
												...this.props.formularioValues,
												id_fornecedor: data
											});
										}
									}} />
							) : null}

							<Field
								name='responsavel'
								component={LabelAndInput}
								label='Responsável' placeholder='Informe o responsável'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='observacao'
								component={LabelAndInput}
								label='Observação' placeholder='Informe as observações'
								cols='12 12 12 12'
								readOnly={readOnly} />

							{!this.props.formularioValues.arquivo_termo && this.props.formularioValues.id ? (
								<Grid cols='12 6 4 3'>
									<label>Termo</label>
									<div className='custom-file'>
										<input
											className=''
											type='file'
											id='customFile'
											value={null}
											onChange={event => {
												let reader = new FileReader();
												let file = event.target.files[0];

												if (file.type.includes('application/pdf') || file.type.includes('image')) {

													reader.onloadend = () => {
														this.props.initForm({
															...this.props.formularioValues,
															arquivo_termo: reader.result
														});
													}

													reader.readAsDataURL(file);
												} else {
													setError('Selecione apenas PDF ou Imagem');
												}

											}} />
									</div>
								</Grid>
							) :  (this.props.formularioValues.id ? (
								<>
									<Grid cols='12 12 12 12'>
										<p><label>Termo</label></p>
									</Grid>
									<Grid cols='12 12 12 12'>
										<a class='btn btn-app'
											onClick={() => {
												const linkSource = this.props.formularioValues.arquivo_termo;
												const downloadLink = document.createElement("a");
												const fileName = 'Inventário';
												downloadLink.href = linkSource;
												downloadLink.download = fileName;
												downloadLink.click();
											}}>
											<i class={`fas fa-${this.props.formularioValues.arquivo_termo.includes('data:image') ? 'file-image'
												: (this.props.formularioValues.arquivo_termo.includes('data:application/pdf') ? 'file-pdf' : 'file-alt')}`}></i> Baixar
										</a>
										<a class='btn btn-app'
											onClick={() => {
												this.props.initForm({
													...this.props.formularioValues,
													arquivo_termo: null
												});
											}}>
											<i class='fas fa-trash'></i> Remover
										</a>
									</Grid>
								</>
							) : null)}
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

EmbalagemInventarioForm = reduxForm({form: 'embalagemInventarioForm', destroyOnUnmount: false})(EmbalagemInventarioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('embalagemInventarioForm')(state),
	registro: state.embalagemInventario.registro,
	listaFornecedor: state.embalagemInventario.listaFornecedor,
	listaEmbalagem: state.embalagemInventario.listaEmbalagem,
	listaEstoqueLocal: state.embalagemInventario.listaEstoqueLocal
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EmbalagemInventarioForm);
