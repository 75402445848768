import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './fornecedorEmbalagemForm';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';
import FormatUtils from '../common/formatUtils/FormatUtils';

import { setModoTela, initForm, salvar, excluir, reativar, getLista, getListaFornecedor, getListaEmbalagem } from './fornecedorEmbalagemActions';

class FornecedorEmbalagem extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaFornecedor();
		this.props.getListaEmbalagem();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>

				{this.state.historico ? this.modalHistorico() : null}

				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro');
									this.props.initForm();
								}} />
						</Grid>
						<Grid cols='6 6 4 2'>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista();
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th>Embalagem</Th>
								<Th>Fornecedor</Th>
								<Th alignCenter>Quantidade</Th>
								<Th alignCenter>Status</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => {
								if (this.state.pesquisar
									&& !(`${item.nome_embalagem} ${item.nome_fornecedor}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									// onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									// onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} 
									>
									<Td>{item.nome_embalagem}</Td>
									<Td>{item.nome_fornecedor}</Td>
									<Td alignCenter>{FormatUtils.formatarValorTela(item.quantidade, 0)}</Td>
									<Td alignCenter>{item.ativo ? 'Ativo' : 'Inativo'}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'primary'}
											icon={'fas fa-list'}
											visible={true /*this.state.linhaSelecionada == item.id*/}
											event={() => {
												this.setState({
													...this.state,
													historico: {
														lista: (item.historico || []).filter(hist => hist),
														nome_embalagem: item.nome_embalagem,
														nome_fornecedor: item.nome_fornecedor
													}
												});
											}} />

										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={true /*this.state.linhaSelecionada == item.id*/}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 0)
												});
												this.props.initForm({
													...item,
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 0)
												});
											}} />

										<ButtonTable
											type={item.ativo ? 'danger' : 'success'}
											icon={item.ativo ? 'fas fa-trash-alt' : 'fas fa-undo'}
											visible={true /*this.state.linhaSelecionada == item.id*/}
											event={() => {
												if (item.ativo) {
													this.props.setModoTela('exclusao', {
														...item,
														quantidade: FormatUtils.formatarValorTela(item.quantidade, 0)
													});
													this.props.initForm({
														...item,
														quantidade: FormatUtils.formatarValorTela(item.quantidade, 0)
													});
												} else {
													this.props.reativar(item);
												}
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}

	modalHistorico() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>{this.state.historico.nome_fornecedor} - {this.state.historico.nome_embalagem}</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, historico: null })}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>
							<Table responsive>
								<THead>
									<Tr>
										<Th alignCenter>Alteração</Th>
										<Th alignCenter>Embalagem</Th>
										<Th alignCenter>Fornecedor</Th>
										<Th alignCenter>Quantidade</Th>
										<Th alignCenter>Status</Th>
										<Th alignCenter>Quantidade</Th>
									</Tr>
								</THead>
								<TBody>
									{this.state.historico.lista.map(item => (
										<Tr key={item.id}>
											<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_alteracao)}</Td>
											<Td alignCenter>{this.state.historico.nome_embalagem}</Td>
											<Td alignCenter>{this.state.historico.nome_fornecedor}</Td>
											<Td alignCenter>{FormatUtils.formatarValorTela(item.quantidade, 0)}</Td>
											<Td alignCenter>{item.ativo ? 'Ativo' : 'Inativo'}</Td>
											<Td alignCenter>{item.nome_usuario_alteracao}</Td>
										</Tr>
									))}
								</TBody>
							</Table>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => this.setState({ ...this.state, historico: null })}>OK</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.fornecedorEmbalagem.modoTela,
	aguardando: state.fornecedorEmbalagem.aguardando,
	lista: state.fornecedorEmbalagem.lista,
	listaFornecedor: state.fornecedorEmbalagem.listaFornecedor,
	listaEmbalagem: state.fornecedorEmbalagem.listaEmbalagem
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, reativar, getLista, getListaFornecedor, getListaEmbalagem }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(FornecedorEmbalagem);
