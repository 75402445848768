import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './embalagemEntradaForm';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import { setModoTela, initForm, setFiltro, salvar, excluir, getLista, getListaFornecedor, getListaEmbalagem, getListaEstoqueLocal } from './embalagemEntradaActions';

class EmbalagemEntrada extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaFornecedor();
		this.props.getListaEmbalagem();
		this.props.getListaEstoqueLocal();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar (Cliente)'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro');
									this.props.initForm({ id_estoque_local: 1 });
								}} />
						</Grid>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar (Fornecedor)'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro');
									this.props.initForm({ id_estoque_local: 2 });
								}} />
						</Grid>
						<Grid cols='6 6 4 2'>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista();
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<LabelAndInputMask
							label='Data Incial' placeholder='Informe a data'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial}
							onChange={data => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
								this.props.getLista();
							}} />

						<LabelAndInputMask
							label='Data Final' placeholder='Informe a data'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final}
							onChange={data => {
								this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
								this.props.getLista();
							}} />
					</Row>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />

					<Table responsive>
						<THead>
							<Tr>
								<Th>Data/Hora</Th>
								<Th>Tipo</Th>
								<Th>Fornecedor</Th>
								<Th>Embalagem</Th>
								<Th>Quantidade</Th>
								<Th>Mínimo</Th>
								{/*<Th>Máximo</Th>*/}
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {
								if (this.state.pesquisar
									&& !(`${DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrada)} ${item.nome_tipo} ${item.nome_fornecedor} ${item.nome_embalagem}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrada)}</Td>
									<Td>{item.nome_tipo}</Td>
									<Td>{item.nome_fornecedor}</Td>
									<Td>{item.nome_embalagem}</Td>
									<Td>{parseInt(item.quantidade)}</Td>
									<Td>{parseInt(item.minimo)}</Td>
									{/*<Td>{parseInt(item.maximo)}</Td>*/}
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													datahora_entrada: DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrada),
													quantidade: parseInt(item.quantidade),
													minimo: parseInt(item.minimo),
													maximo: parseInt(item.maximo)
												});
												this.props.initForm({
													...item,
													datahora_entrada: DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrada),
													quantidade: parseInt(item.quantidade),
													minimo: parseInt(item.minimo),
													maximo: parseInt(item.maximo)
												});
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													datahora_entrada: DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrada),
													quantidade: parseInt(item.quantidade),
													minimo: parseInt(item.minimo),
													maximo: parseInt(item.maximo)
												});
												this.props.initForm({
													...item,
													datahora_entrada: DateFormat.formatarDataHoraSqlParaTela(item.datahora_entrada),
													quantidade: parseInt(item.quantidade),
													minimo: parseInt(item.minimo),
													maximo: parseInt(item.maximo)
												});
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.embalagemEntrada.modoTela,
	aguardando: state.embalagemEntrada.aguardando,
	filtro: state.embalagemEntrada.filtro,
	lista: state.embalagemEntrada.lista,
	listaTipo: state.embalagemEntrada.listaTipo,
	listaFornecedor: state.embalagemEntrada.listaFornecedor,
	listaEmbalagem: state.embalagemEntrada.listaEmbalagem,
	listaEstoqueLocal: state.embalagemEntrada.listaEstoqueLocal
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, setFiltro, salvar, excluir, getLista, getListaFornecedor, getListaEmbalagem, getListaEstoqueLocal }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EmbalagemEntrada);
