import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Select from '../common/form/select';

import { adicionarFornecedor, removerFornecedor, getListaTransportadoraFornecedor, getListaFornecedor }
from './transportadoraActions';

class TransportadoraFornecedor extends Component {

	state = {
		linhaSelecionada: null,
		id_fornecedor: null
	}

    componentWillMount() {
        this.props.getListaTransportadoraFornecedor(this.props.registro.id);
		this.props.getListaFornecedor();
    }

    render() {
        return (
            <div>
                <Content>
					{this.lista()}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Select
							options={this.props.listaFornecedor}
							label='Fornecedor'
							cols='12 12 6 6'
							placeholder='Selecione o fornecedor'
							value={this.state.id_fornecedor}
							onChange={data => {
								if (data) {
									this.props.adicionarFornecedor({
										id_transportadora: this.props.registro.id,
										id_fornecedor: data
									});
								}


							}} />
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Nome</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.listaTransportadoraFornecedor.map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.nome_fornecedor}</Td>
                                    <Td alignRight minWidth={100}>
										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.removerFornecedor(item);
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.transportadora.modoTela,
	registro: state.transportadora.registro,
	listaTransportadoraFornecedor: state.transportadora.listaTransportadoraFornecedor,
	listaFornecedor: state.transportadora.listaFornecedor
});
const mapDispatchToProps = dispatch => bindActionCreators({ adicionarFornecedor, removerFornecedor, getListaTransportadoraFornecedor, getListaFornecedor }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(TransportadoraFornecedor);
