import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaFornecedor: [],
	listaEstoqueLocal: [],
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
	}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'EMBALAGEM_INVENTARIO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'EMBALAGEM_INVENTARIO_FILTRO':
            return { ...state,
				filtro: action.payload.filtro
			};

        case 'EMBALAGEM_INVENTARIO_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        case 'EMBALAGEM_INVENTARIO_FORNECEDOR_SELECT_LISTADO':
			return {
				...state,
				listaFornecedor: action.payload.data
			};

        case 'EMBALAGEM_INVENTARIO_ESTOQUE_LOCAL_SELECT_LISTADO':
			return {
				...state,
				listaEstoqueLocal: action.payload.data
			};

        default:
            return state;
    }
}
