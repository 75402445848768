import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import FormItem from './embalagemViagemItemForm';
import FormEvento from './embalagemViagemEventoForm';
import { setError } from '../common/toast/toast';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import imagemAdd from '../assets/images/icone_add_imagem.png';

import {
	setModoTela, initForm, finalizar, reabrir,
	setModoTelaItem, initFormItem, salvarItem, excluirItem, getListaItem, setListaItem, setListaEvento,
	getListaEventos, getListaLocalizacao, gerarComprovanteEntrega,
	setModoTelaEvento, initFormEvento, salvarEvento, excluirEvento
} from './embalagemViagemActions';

class EmbalagemViagemForm extends Component {

	state = {

	}

	componentDidMount() {

		let initSeletorPdfAbertura = () => {
			const fileSelectorPdfAbertura = document.createElement('input');
			fileSelectorPdfAbertura.setAttribute('type', 'file');
			fileSelectorPdfAbertura.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('application/pdf')
						&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {

						let lista = (this.props.formularioValues.pdf_abertura || []).map(item => item);
						lista.push({
							pdf_abertura_base64: reader.result
						});

						this.props.initForm({
							...this.props.formularioValues,
							pdf_abertura: lista
						});

						initSeletorPdfAbertura();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelectorPdfAbertura = fileSelectorPdfAbertura;
		};

		initSeletorPdfAbertura();

		let initSeletorPdfFechamento = () => {
			const fileSelectorPdfFechamento = document.createElement('input');
			fileSelectorPdfFechamento.setAttribute('type', 'file');
			fileSelectorPdfFechamento.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('application/pdf')
						&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {

						let lista = (this.props.formularioValues.pdf_fechamento || []).map(item => item);
						lista.push({
							pdf_fechamento_base64: reader.result
						});

						this.props.initForm({
							...this.props.formularioValues,
							pdf_fechamento: lista
						});

						initSeletorPdfFechamento();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelectorPdfFechamento = fileSelectorPdfFechamento;
		};

		initSeletorPdfFechamento();

		let initSeletorImagem = () => {
			const fileSelectorImagem = document.createElement('input');
			fileSelectorImagem.setAttribute('type', 'file');
			fileSelectorImagem.addEventListener("change", (data) => {

				let reader = new FileReader();
				let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
				if (!file) {
					file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
				}

				if (file) {

					if (!file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
						setError('Selecione apenas imagens (.PNG, .JPG e .JPEG)');
						return;
					}

					reader.onloadend = () => {

						let lista = (this.props.formularioValues.imagem || []).map(item => item);
						lista.push({
							imagem_base64: reader.result
						});

						let imagemFonte = (this.props.formularioValues.imagem_fonte || []).map(item => item);
						imagemFonte.push('');

						this.props.initForm({
							...this.props.formularioValues,
							imagem: lista,
							imagem_fonte: imagemFonte
						});

						initSeletorImagem();

					}

					reader.readAsDataURL(file);

				}
			});

			this.fileSelectorImagem = fileSelectorImagem;
		};

		initSeletorImagem();

	}

    componentWillMount() {
		//Apenas Letsara
		this.props.getListaEventos(this.props.formularioValues ? this.props.formularioValues.id_transportadora : null, this.props.formularioValues ? this.props.formularioValues.id : null, this.props.formularioValues && this.props.formularioValues.id_transportadora == 1 ? this.props.formularioValues.solicitacao : null);
		this.props.getListaLocalizacao(this.props.formularioValues && this.props.formularioValues.id_transportadora == 1 ? this.props.formularioValues.solicitacao : null);
    }

    render() {

		let readOnly = this.props.excluir || this.props.visualizacao || (this.props.formularioValues && this.props.formularioValues.id_status && ((this.props.formularioValues.id_status == 2 && !this.props.comprovantes) || this.props.formularioValues.id_status == 3)) ? 'readOnly' : '';

		let origem = this.props.listaEstoqueLocal.filter(item => item.id == this.props.formularioValues.id_origem)[0];
		let destino = this.props.listaEstoqueLocal.filter(item => item.id == this.props.formularioValues.id_destino)[0];

        return (
			<div>
				<ContentCard>
					<Form event={this.props.handleSubmit}>

						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Cancelar'
											submit type={'danger'}
											icon={'fa fa-trash'}
											disabled={this.props.visualizacao} />
									) : (!this.props.formularioValues || !this.props.formularioValues.id_status || this.props.formularioValues.id_status == 1 || (this.props.formularioValues.id_status == 2 && this.props.comprovantes) ? (
										<Button
											text='Salvar'
											submit
											type={'success'}
											icon={'fa fa-check'}
											disabled={this.props.visualizacao} />
									) : null)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>

						<ContentCardBody>

							{!this.props.comprovantes ? (
								<>
									<Row>
										{this.props.formularioValues && this.props.formularioValues.id ? (
											<Select
												name='id_status'
												options={this.props.listaStatus}
												label='Status'
												cols='12 12 4 4'
												placeholder='Selecione o status'
												readOnly={'readOnly'}
												value={this.props.formularioValues.id_status}
												onChange={data => {
													if (data) {
														this.props.initForm({
															...this.props.formularioValues,
															id_status: data
														});
													}
												}} />
										) : null}

										<Field
											name='datahora_viagem'
											component={LabelAndInputMask}
											label='Data/Hora' placeholder='Informe a data e hora da viagem'
											cols='12 6 4 4'
											mask='99/99/9999 99:99'
											readOnly={readOnly} />

										{this.props.formularioValues && this.props.formularioValues.datahora_finalizacao ? (
											<Field
												name='datahora_finalizacao'
												component={LabelAndInputMask}
												label='Finalização' placeholder='Informe a data e hora da finalização'
												cols='12 6 4 4'
												mask='99/99/9999 99:99'
												readOnly={readOnly} />
										) : null}

										<Select
											name='id_transportadora'
											options={this.props.listaTransportadora}
											label='Transportadora'
											cols='12 12 12 12'
											placeholder='Selecione a transportadora'
											readOnly={readOnly ? readOnly : (this.props.formularioValues.id ? 'readOnly' : (this.props.sessao.id_transportadora ? 'readOnly' : null))}
											value={this.props.formularioValues.id_transportadora}
											onChange={data => {
												if (data) {
													this.props.initForm({
														...this.props.formularioValues,
														id_transportadora: data
													});
												}
											}} />

										<Field
											name='viagem'
											component={LabelAndInputNumber}
											label='Viagem' placeholder='Informe a viagem'
											cols='12 6 4 4'
											casas={0}
											readOnly='readOnly' />

										<Field
											name='solicitacao'
											component={LabelAndInput}
											label='Solicitação' placeholder='Informe a solicitacao'
											cols='12 6 4 4'
											casas={0}
											readOnly={readOnly} />

										<Field
											name='fatura'
											component={LabelAndInput}
											label='Fatura' placeholder='Informe a fatura'
											cols='12 6 4 4'
											casas={0}
											readOnly={readOnly} />

										<Field
											name='conhecimento_transporte'
											component={LabelAndInput}
											label='Conhecimento de Transporte' placeholder='Informe o número'
											cols='12 6 4 4'
											readOnly={readOnly} />

										<Field
											name='carga_manual'
											component={LabelAndInput}
											label='Embarque GMA' placeholder='Informe o número'
											cols='12 6 4 4'
											readOnly={readOnly} />
									</Row>
									<Row>
										<Field
											name='cavalo'
											component={LabelAndInput}
											label='Cavalo' placeholder='Informe a placa'
											cols='12 6 4 4'
											readOnly={readOnly} />

										<Field
											name='carreta1'
											component={LabelAndInput}
											label='Carreta 1' placeholder='Informe a placa'
											cols='12 6 4 4'
											readOnly={readOnly} />

										<Field
											name='carreta2'
											component={LabelAndInput}
											label='Carreta 2' placeholder='Informe a placa'
											cols='12 6 4 4'
											readOnly={readOnly} />
									</Row>
									<Row>
										<Select
											name='id_origem'
											options={this.props.listaEstoqueLocal}
											label='Origem'
											cols='12 12 6 6'
											placeholder='Selecione o local'
											readOnly={readOnly}
											value={this.props.formularioValues.id_origem}
											onChange={data => {
												if (data) {
													this.props.initForm({
														...this.props.formularioValues,
														id_origem: data,
														id_fornecedor_origem: null
													});
												}
											}} />

										{this.props.formularioValues.id_origem == 2 ? ( //Se a origem da embalagem é o fornecedor
											<Select
												name='id_fornecedor_origem'
												options={this.props.listaFornecedor.filter(item => this.props.formularioValues.id_transportadora && item.transportadoras.includes(this.props.formularioValues.id_transportadora))}
												label='Fornecedor de Origem'
												cols='12 12 6 6'
												placeholder='Selecione o fornecedor de origem'
												readOnly={readOnly}
												value={this.props.formularioValues.id_fornecedor_origem}
												onChange={data => {
													if (data) {
														this.props.initForm({
															...this.props.formularioValues,
															id_fornecedor_origem: data
														});
													}
												}} />
										) : null}

									</Row>
									<Row>

										<Select
											name='id_destino'
											options={this.props.listaEstoqueLocal}
											label='Destino'
											cols='12 12 6 6'
											placeholder='Selecione o local'
											readOnly={readOnly}
											value={this.props.formularioValues.id_destino}
											onChange={data => {
												if (data) {
													this.props.initForm({
														...this.props.formularioValues,
														id_destino: data,
														id_fornecedor_destino: null
													});
												}
											}} />

										{this.props.formularioValues.id_destino == 2 ? ( //Se o destino da embalagem é o fornecedor
											<Select
												name='id_fornecedor_destino'
												options={this.props.listaFornecedor.filter(item => this.props.formularioValues.id_transportadora && item.transportadoras.includes(this.props.formularioValues.id_transportadora))}
												label='Fornecedor de Destino'
												cols='12 12 6 6'
												placeholder='Selecione o fornecedor de destino'
												readOnly={readOnly}
												value={this.props.formularioValues.id_fornecedor_destino}
												onChange={data => {
													if (data) {
														this.props.initForm({
															...this.props.formularioValues,
															id_fornecedor_destino: data
														});
													}
												}} />
										) : null}

									</Row>
									<Row>
										<LabelAndInput
											label='Localização' placeholder='Informe a solicitação'
											cols='12 12 12 12'
											value={this.props.localizacao ? this.props.localizacao.descricao : (this.props.formularioValues.localizacao)}
											onChange={() => {}}
											readOnly={'readOnly'} />
									</Row>

									<Row>
										<Grid cols='12 12 12 12'>
											<label>Packing list de abertura</label>
										</Grid>
										{(this.props.formularioValues.pdf_abertura || []).map(arquivo => (
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<span class="btn btn-outline-primary fileinput-button dz-clickable">
													<img src={arquivo.pdf_abertura_base64 ? pdfAdicionado : (arquivo.pdf ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
												</span>
												<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
													<button
														type='button'
														class='btn btn-flat btn-outline-primary'
														style={{ width: '48%' }}
														disabled={arquivo.pdf_abertura_base64 ?true : false}
														onClick={() => {
															window.open(`https://api-letsarareports.infinitum-ti.com/${arquivo.pdf}`, '_blank');
														}}>
														<i class='fas fa-download'></i>
													</button>

													<button
														type='button'
														class='btn btn-flat btn-outline-danger'
														style={{ width: '48%' }}
														disabled={readOnly}
														onClick={() => {
															this.props.initForm({
																...this.props.formularioValues,
																pdf_abertura: this.props.formularioValues.pdf_abertura.filter(item => item != arquivo)
															});
														}}>
														<i class='fas fa-trash-alt'></i>
													</button>
												</div>
											</div>
										))}
										{!readOnly ? (
											<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorPdfAbertura.click()}>
												<img src={pdfAdd} style={{ height: 30 }} />
											</span>
										) : null}
									</Row>
								</>
							) : null}

							<br />
							<Row>
								<Grid cols='12 12 12 12'>
									<label>Comprovante de entrega/fechamento</label>
								</Grid>
								{(this.props.formularioValues.pdf_fechamento || []).map(arquivo => (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={arquivo.pdf_fechamento_base64 ? pdfAdicionado : (arquivo.pdf ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '48%' }}
												disabled={arquivo.pdf_fechamento_base64 ? true : false}
												onClick={() => {
													window.open(`https://api-letsarareports.infinitum-ti.com/${arquivo.pdf}`, '_blank');
												}}>
												<i class='fas fa-download'></i>
											</button>

											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: '48%' }}
												disabled={readOnly || !this.props.comprovantes}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														pdf_fechamento: this.props.formularioValues.pdf_fechamento.filter(item => item != arquivo)
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									</div>
								))}

								{!readOnly && this.props.comprovantes ? (
									<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorPdfFechamento.click()}>
										<img src={pdfAdd} style={{ height: 30 }} />
									</span>
								) : null}
							</Row>

							<br />

							<Row>
								<Grid cols='12 12 12 12'>
									<label>Fotos da Carga</label>
								</Grid>
								{(this.props.formularioValues.imagem || []).map((arquivo, i) => (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={arquivo.imagem_base64 ? arquivo.imagem_base64 : (arquivo.imagem ? `https://api-letsarareports.infinitum-ti.com/${arquivo.imagem}` : imagemAdd)} style={{ height: 30 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '48%' }}
												disabled={arquivo.imagem_base64 ? true : false}
												onClick={() => {
													window.open(`https://api-letsarareports.infinitum-ti.com/${arquivo.imagem}`, '_blank');
												}}>
												<i class='fas fa-download'></i>
											</button>

											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: '48%' }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														imagem: this.props.formularioValues.imagem.filter(item => item != arquivo)
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									</div>
								))}

								{!readOnly ? (
									<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorImagem.click()}>
										<img src={imagemAdd} style={{ height: 30 }} />
									</span>
								) : null}
							</Row>
						</ContentCardBody>

					</Form>
				</ContentCard>
				{this.props.formularioValues.id && !this.props.comprovantes ?
					this.renderItens(readOnly)
					: null
				}
				{this.props.formularioValues.id && !this.props.comprovantes ?
					this.renderEventos(readOnly)
					: null
				}

				{this.props.formularioValues.id && this.props.visualizacao && !this.props.comprovantes ?
					this.renderHistorico()
					: null
				}

			</div>
        )
    }

	renderItens(readOnly) {

		if (this.props.modoTelaItem == 'cadastro') {
			return (
				<FormItem onSubmit={this.props.salvarItem} />
			);
		} else if (this.props.modoTelaItem == 'exclusao') {
			return (
				<FormItem excluir onSubmit={this.props.excluirItem} />
			);
		} else if (this.props.modoTelaItem == 'lista') {
			return (

				<ContentCard>
					<ContentCardHeader>
						<Row>
							<Grid cols='6 6 4 2'>
								{this.props.formularioValues && this.props.formularioValues.id_status == 1 ? (
									<Button
										text='Adicionar'
										type={'success'}
										icon={'fa fa-plus'}
										disabled={readOnly ? true : false}
										event={() => {
											this.props.setModoTelaItem('cadastro', {
												id_viagem: this.props.formularioValues.id,
												id_transportadora: this.props.formularioValues.id_transportadora
											});
											this.props.initFormItem({
												id_viagem: this.props.formularioValues.id,
												id_transportadora: this.props.formularioValues.id_transportadora
											});
										}} />
								) : null}
							</Grid>
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th>Embalagem</Th>
									<Th>Quantidade</Th>
									<Th></Th>
								</Tr>
							</THead>
							<TBody>
								{this.props.listaItem.map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td>{item.nome_embalagem}</Td>
										<Td>{parseInt(item.quantidade || 0)}</Td>
										<Td alignRight minWidth={100}>
											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={true}
												disabled={readOnly ? true : false}
												event={() => {
													this.props.setModoTelaItem('cadastro', {
														...item,
														quantidade: String(item.quantidade || '').replace('.', ',')
													});
													this.props.initFormItem({
														...item,
														quantidade: String(item.quantidade || '').replace('.', ',')
													});
												}} />

											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={true}
												disabled={readOnly ? true : false}
												event={() => {
													this.props.setModoTelaItem('exclusao', {
														...item,
														quantidade: String(item.quantidade || '').replace('.', ',')
													});
													this.props.initFormItem({
														...item,
														quantidade: String(item.quantidade || '').replace('.', ',')
													});
												}} />
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			);
		} else {
			return (
				<div></div>
			);
		}
	}

	renderEventos(readOnly) {

		if (this.props.modoTelaEvento == 'cadastro') {
			return (
				<FormEvento onSubmit={this.props.salvarEvento} />
			);
		} else if (this.props.modoTelaEvento == 'exclusao') {
			return (
				<FormEvento excluir onSubmit={this.props.excluirEvento} />
			);
		} else if (this.props.modoTelaEvento == 'lista') {

			return (

				<ContentCard>
					<ContentCardHeader>
						<Row>
							<Grid cols='6 6 4 2'>
								{this.props.formularioValues && this.props.formularioValues.id_status == 1 ? (
									<Button
										text='Adicionar'
										type={'success'}
										icon={'fa fa-plus'}
										disabled={readOnly ? true : false}
										event={() => {
											this.props.setModoTelaEvento('cadastro', {
												id_viagem: this.props.formularioValues.id,
												cavalo: this.props.listaEventos[this.props.listaEventos.length -1] ? this.props.listaEventos[this.props.listaEventos.length -1].cavalo : this.props.formularioValues.cavalo,
												carretas: this.props.listaEventos[this.props.listaEventos.length -1] ? this.props.listaEventos[this.props.listaEventos.length -1].carretas : `${this.props.formularioValues.carreta1 || ''}${this.props.formularioValues.carreta2 ? ', ' : ''}${this.props.formularioValues.carreta2 || ''}`
											});
											this.props.initFormEvento({
												id_viagem: this.props.formularioValues.id,
												cavalo: this.props.listaEventos[this.props.listaEventos.length -1] ? this.props.listaEventos[this.props.listaEventos.length -1].cavalo : this.props.formularioValues.cavalo,
												carretas: this.props.listaEventos[this.props.listaEventos.length -1] ? this.props.listaEventos[this.props.listaEventos.length -1].carretas : `${this.props.formularioValues.carreta1 || ''}${this.props.formularioValues.carreta2 ? ', ' : ''}${this.props.formularioValues.carreta2 || ''}`
											});
										}} />
								) : null}
							</Grid>
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th>Data/Hora</Th>
									<Th>Evento</Th>
									<Th>Cavalo</Th>
									<Th>Carretas</Th>
									<Th></Th>
								</Tr>
							</THead>
							<TBody>
								{this.props.listaEventos.map(item => (
									<Tr key={item.id}>
										<Td>{item.id_tipo ? DateFormat.formatarDataHoraSqlParaTela(item.datahora_evento) : item.datahora_evento}</Td>
										<Td>{item.nome_evento}</Td>
										<Td>{item.cavalo}</Td>
										<Td>{item.carretas}</Td>
										<Td alignRight minWidth={100}>
											{item.id_tipo ? (
												<ButtonTable
													type={'warning'}
													icon={'fas fa-pencil-alt'}
													visible={true}
													disabled={readOnly ? true : false}
													event={() => {
														this.props.setModoTelaEvento('cadastro', {
															...item,
															datahora_evento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_evento)
														});
														this.props.initFormEvento({
															...item,
															datahora_evento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_evento)
														});
													}} />
											) : null}
											{item.id_tipo ? (
												<ButtonTable
													type={'danger'}
													icon={'fas fa-trash-alt'}
													visible={true}
													disabled={readOnly ? true : false}
													event={() => {
														this.props.setModoTelaEvento('exclusao', {
															...item,
															datahora_evento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_evento)
														});
														this.props.initFormEvento({
															...item,
															datahora_evento: DateFormat.formatarDataHoraSqlParaTela(item.datahora_evento)
														});
													}} />
											) : null}
										</Td>
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			);
		}
	}

	renderHistorico() {

		return (

			<ContentCard>
				<ContentCardHeader>
					<label>Históricos</label>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Data/Hora</Th>
								<Th>Usuário</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.listaHistorico.map(item => (
								<Tr key={item.id}>
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_alteracao)}</Td>
									<Td>{item.nome_usuario_alteracao}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'success'}
											icon={'fas fa-code-branch'}
											visible={true}
											event={() => {
												this.props.setModoTela('visualizacao', {
													...item.registro,
													datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.registro.datahora_viagem),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.registro.datahora_finalizacao)
												});
												this.props.initForm({
													...item.registro,
													datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.registro.datahora_viagem),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.registro.datahora_finalizacao)
												});
												console.log(item);
												this.props.setListaItem(item.itens || []);
												this.props.setListaEvento(item.eventos || []);
												window.scrollTo(0, 0);
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);

	}

}

EmbalagemViagemForm = reduxForm({form: 'embalagemViagemForm', destroyOnUnmount: false})(EmbalagemViagemForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('embalagemViagemForm')(state),
	registro: state.embalagemViagem.registro,
	listaItem: state.embalagemViagem.listaItem,
	cliente: state.cliente.lista.filter(item => item.id == state.auth.usuario.id_cliente)[0],
	listaTransportadora: state.embalagemViagem.listaTransportadora,
	listaFornecedor: state.embalagemViagem.listaFornecedor,
	listaEmbalagem: state.embalagemViagem.listaEmbalagem,
	listaEstoqueLocal: state.embalagemViagem.listaEstoqueLocal,
	listaStatus: state.embalagemViagem.listaStatus,
	listaSentido: state.embalagemViagem.listaSentido,
	listaEventos: state.embalagemViagem.listaEventos,
	listaHistorico: state.embalagemViagem.listaHistorico,
	localizacao: state.embalagemViagem.localizacao,
	modoTelaItem: state.embalagemViagem.modoTelaItem,
	modoTelaEvento: state.embalagemViagem.modoTelaEvento
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, finalizar, reabrir,
	setModoTelaItem, initFormItem, salvarItem, excluirItem, getListaItem, setListaItem, setListaEvento,
	getListaEventos, getListaLocalizacao, gerarComprovanteEntrega,
	setModoTelaEvento, initFormEvento, salvarEvento, excluirEvento
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EmbalagemViagemForm);
