import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import TransportadoraFornecedor from './transportadoraFornecedor';

import {
	setModoTela, initForm
} from './transportadoraActions';

class TransportadoraForm extends Component {

	state = {

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<ContentCardBody>
							<Row>
								<Field
									name='razao_social'
									component={LabelAndInput}
									label='Razão Social' placeholder='Informe a razão social'
									cols='12 12 12 12'
									readOnly={readOnly} />

								<Field
									name='nome_fantasia'
									component={LabelAndInput}
									label='Nome Fantasia' placeholder='Informe o nome fantasia'
									cols='12 12 12 12'
									readOnly={readOnly} />

								<Field
									name='cnpj'
									component={LabelAndInputMask}
									label='CNPJ' placeholder='Informe o CNPJ'
									cols='12 12 12 12'
									mask='99.999.999/9999-99'
									readOnly={readOnly} />
							</Row>
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Excluir'
											submit type={'danger'}
											icon={'fa fa-trash'} />
									) : (
									<Button
										text='Salvar'
										submit
										type={'success'}
										icon={'fa fa-check'} />
									)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</Form>
				</ContentCard>

				{this.props.formularioValues.id ? (
					<TransportadoraFornecedor />
				) : null}
			</>
        )
    }


}

TransportadoraForm = reduxForm({form: 'transportadoraForm', destroyOnUnmount: false})(TransportadoraForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('transportadoraForm')(state),
	registro: state.transportadora.registro,
	listaEstoqueLocal: state.transportadora.listaEstoqueLocal
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(TransportadoraForm);
