import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './embalagemForm';
import Aguardando from '../common/template/aguardando';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import FormatUtils from '../common/formatUtils/FormatUtils';

import { setModoTela, initForm, salvar, excluir, getLista, reativar } from './embalagemActions';

class Embalagem extends Component {

	state = {
		linhaSelecionada: null,
		inativo: false
	}

    componentWillMount() {
        this.props.getLista();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm({});
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<LabelAndCheckbox
							label='Listar Inativos'
							cols='6 4 3 2'
							value={this.state.inativo}
							onChange={(data) => {
								this.setState({ ...this.state, inativo: !this.state.inativo });
							}} />
					</Row>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />
					<Table responsive>
						<THead>
							<Tr>
								<Th>ID Operação</Th>
								<Th>Nome</Th>
								<Th alignCenter>Qtd Adquirida</Th>
								<Th>Tipo</Th>
								<Th alignCenter>Status</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.lista.filter(item => {
								if (!this.state.inativo && !item.ativo) {
									return false;
								}
								if (this.state.pesquisar
									&& !(`${item.identificador_cliente} ${item.nome} ${item.nome_tipo}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map(item => (
								<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{item.identificador_cliente}</Td>
									<Td>{item.nome}</Td>
									<Td alignCenter>{parseInt(item.quantidade, 0)}</Td>
									<Td>{item.nome_tipo}</Td>
									<Td alignCenter>{item.ativo ? 'Ativo' : 'Inativo'}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 0),
													largura: FormatUtils.formatarValorTela(item.largura, 2),
													altura: FormatUtils.formatarValorTela(item.altura, 2),
													comprimento: FormatUtils.formatarValorTela(item.comprimento, 2),
													peso_bruto: FormatUtils.formatarValorTela(item.peso_bruto, 2),
													peso_liquido: FormatUtils.formatarValorTela(item.peso_liquido, 2),
													quantidade_palete: FormatUtils.formatarValorTela(item.quantidade_palete, 0)
												});
												this.props.initForm({
													...item,
													quantidade: FormatUtils.formatarValorTela(item.quantidade, 0),
													largura: FormatUtils.formatarValorTela(item.largura, 2),
													altura: FormatUtils.formatarValorTela(item.altura, 2),
													comprimento: FormatUtils.formatarValorTela(item.comprimento, 2),
													peso_bruto: FormatUtils.formatarValorTela(item.peso_bruto, 2),
													peso_liquido: FormatUtils.formatarValorTela(item.peso_liquido, 2),
													quantidade_palete: FormatUtils.formatarValorTela(item.quantidade_palete, 0)
												});
											}} />

										<ButtonTable
											type={item.ativo ? 'danger' : 'success'}
											icon={item.ativo ? 'fas fa-trash-alt' : 'fas fa-undo'}
											visible={this.state.linhaSelecionada == item.id}
											event={() => {
												if (item.ativo) {
													this.props.setModoTela('exclusao', {
														...item,
														quantidade: FormatUtils.formatarValorTela(item.quantidade, 0),
														largura: FormatUtils.formatarValorTela(item.largura, 2),
														altura: FormatUtils.formatarValorTela(item.altura, 2),
														comprimento: FormatUtils.formatarValorTela(item.comprimento, 2),
														peso_bruto: FormatUtils.formatarValorTela(item.peso_bruto, 2),
														peso_liquido: FormatUtils.formatarValorTela(item.peso_liquido, 2),
														quantidade_palete: FormatUtils.formatarValorTela(item.quantidade_palete, 0)
													});
													this.props.initForm({
														...item,
														quantidade: FormatUtils.formatarValorTela(item.quantidade, 0),
														largura: FormatUtils.formatarValorTela(item.largura, 2),
														altura: FormatUtils.formatarValorTela(item.altura, 2),
														comprimento: FormatUtils.formatarValorTela(item.comprimento, 2),
														peso_bruto: FormatUtils.formatarValorTela(item.peso_bruto, 2),
														peso_liquido: FormatUtils.formatarValorTela(item.peso_liquido, 2),
														quantidade_palete: FormatUtils.formatarValorTela(item.quantidade_palete, 0)
													});
												} else {
													this.props.reativar(item);
												}
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.embalagem.modoTela,
	aguardando: state.embalagem.aguardando,
	lista: state.embalagem.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista, reativar }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Embalagem);
