import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import TableScroll from '../common/table/tableScroll';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';

import ReactExport from 'react-export-excel-fixed-xlsx';

import { setFiltro, getLista } from './dashboardInventarioFornecedorActions';
import { getLista as getListaCliente } from '../cliente/clienteActions';

class DashboardInventarioFornecedor extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null
	}

    componentWillMount() {
		this.props.getLista();
    }

    render() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let lista = this.props.lista.filter(item => {
			if (this.state.pesquisar
				&& !(`${item.nome_fornecedor || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {

				return false;
			}

			return true;
		}).map((item, i) => {

				return {
					...item,
					realizados: item.semanas.length,
					status: parseInt((item.semanas.length * 100) / 52),
					performance: parseInt((item.semanas.length * 100) / (new Date().getFullYear() == this.props.filtro.ano ? parseInt(item.semana_atual) : 52))
				};

		});

        return (
            <div>

				<Aguardando aguardando={this.props.aguardando} />

				{this.state.fornecedor_selecionado ? this.modalInventarios() : null}

                <Content>
					<ContentCard>
						<ContentCardHeader>
							<Row>
								<Grid cols='6 6 4 2'>
									<Button
										text='Atualizar'
										type={'primary'}
										icon={'fa fa-plus'}
										event={() => {
											this.props.getLista();
										}} />
								</Grid>
								<Grid cols='6 6 4 2'>
									<ExcelFile
										filename={'Dashboard'}
										element={
											<Button
												text='Exportar'
												type={'primary'}
												icon={'fa fa-plus'} />
										}>
										<ExcelSheet
											data={lista.map(item => ({
												...item,
												nome_fornecedor: item.nome_fornecedor.split('-').filter((nome, i) => i > 0).join('-'),
												semana1: item.semanas.filter(semana => semana == 1).length ? 'OK' : '',
												semana2: item.semanas.filter(semana => semana == 2).length ? 'OK' : '',
												semana3: item.semanas.filter(semana => semana == 3).length ? 'OK' : '',
												semana4: item.semanas.filter(semana => semana == 4).length ? 'OK' : '',
												semana5: item.semanas.filter(semana => semana == 5).length ? 'OK' : '',
												semana6: item.semanas.filter(semana => semana == 6).length ? 'OK' : '',
												semana7: item.semanas.filter(semana => semana == 7).length ? 'OK' : '',
												semana8: item.semanas.filter(semana => semana == 8).length ? 'OK' : '',
												semana9: item.semanas.filter(semana => semana == 9).length ? 'OK' : '',
												semana10: item.semanas.filter(semana => semana == 10).length ? 'OK' : '',
												semana11: item.semanas.filter(semana => semana == 11).length ? 'OK' : '',
												semana12: item.semanas.filter(semana => semana == 12).length ? 'OK' : '',
												semana13: item.semanas.filter(semana => semana == 13).length ? 'OK' : '',
												semana14: item.semanas.filter(semana => semana == 14).length ? 'OK' : '',
												semana15: item.semanas.filter(semana => semana == 15).length ? 'OK' : '',
												semana16: item.semanas.filter(semana => semana == 16).length ? 'OK' : '',
												semana17: item.semanas.filter(semana => semana == 17).length ? 'OK' : '',
												semana18: item.semanas.filter(semana => semana == 18).length ? 'OK' : '',
												semana19: item.semanas.filter(semana => semana == 19).length ? 'OK' : '',
												semana20: item.semanas.filter(semana => semana == 20).length ? 'OK' : '',
												semana21: item.semanas.filter(semana => semana == 21).length ? 'OK' : '',
												semana22: item.semanas.filter(semana => semana == 22).length ? 'OK' : '',
												semana23: item.semanas.filter(semana => semana == 23).length ? 'OK' : '',
												semana24: item.semanas.filter(semana => semana == 24).length ? 'OK' : '',
												semana25: item.semanas.filter(semana => semana == 25).length ? 'OK' : '',
												semana26: item.semanas.filter(semana => semana == 26).length ? 'OK' : '',
												semana27: item.semanas.filter(semana => semana == 27).length ? 'OK' : '',
												semana28: item.semanas.filter(semana => semana == 28).length ? 'OK' : '',
												semana29: item.semanas.filter(semana => semana == 29).length ? 'OK' : '',
												semana30: item.semanas.filter(semana => semana == 30).length ? 'OK' : '',
												semana31: item.semanas.filter(semana => semana == 31).length ? 'OK' : '',
												semana32: item.semanas.filter(semana => semana == 32).length ? 'OK' : '',
												semana33: item.semanas.filter(semana => semana == 33).length ? 'OK' : '',
												semana34: item.semanas.filter(semana => semana == 34).length ? 'OK' : '',
												semana35: item.semanas.filter(semana => semana == 35).length ? 'OK' : '',
												semana36: item.semanas.filter(semana => semana == 36).length ? 'OK' : '',
												semana37: item.semanas.filter(semana => semana == 37).length ? 'OK' : '',
												semana38: item.semanas.filter(semana => semana == 38).length ? 'OK' : '',
												semana39: item.semanas.filter(semana => semana == 39).length ? 'OK' : '',
												semana40: item.semanas.filter(semana => semana == 40).length ? 'OK' : '',
												semana41: item.semanas.filter(semana => semana == 41).length ? 'OK' : '',
												semana42: item.semanas.filter(semana => semana == 42).length ? 'OK' : '',
												semana43: item.semanas.filter(semana => semana == 43).length ? 'OK' : '',
												semana44: item.semanas.filter(semana => semana == 44).length ? 'OK' : '',
												semana45: item.semanas.filter(semana => semana == 45).length ? 'OK' : '',
												semana46: item.semanas.filter(semana => semana == 46).length ? 'OK' : '',
												semana47: item.semanas.filter(semana => semana == 47).length ? 'OK' : '',
												semana48: item.semanas.filter(semana => semana == 48).length ? 'OK' : '',
												semana49: item.semanas.filter(semana => semana == 49).length ? 'OK' : '',
												semana50: item.semanas.filter(semana => semana == 50).length ? 'OK' : '',
												semana51: item.semanas.filter(semana => semana == 51).length ? 'OK' : '',
												semana52: item.semanas.filter(semana => semana == 52).length ? 'OK' : ''
											}))}

											name='Dashboard'>
											<ExcelColumn label='Fornecedor' value='nome_fornecedor' />
											<ExcelColumn label='Realizado' value='realizados' />
											<ExcelColumn label='Status' value='status' />
											<ExcelColumn label='Performance' value='performance' />
											<ExcelColumn label='1' value='semana1' />
											<ExcelColumn label='2' value='semana2' />
											<ExcelColumn label='3' value='semana3' />
											<ExcelColumn label='4' value='semana4' />
											<ExcelColumn label='5' value='semana5' />
											<ExcelColumn label='6' value='semana6' />
											<ExcelColumn label='7' value='semana7' />
											<ExcelColumn label='8' value='semana8' />
											<ExcelColumn label='9' value='semana9' />
											<ExcelColumn label='10' value='semana10' />
											<ExcelColumn label='11' value='semana11' />
											<ExcelColumn label='12' value='semana12' />
											<ExcelColumn label='13' value='semana13' />
											<ExcelColumn label='14' value='semana14' />
											<ExcelColumn label='15' value='semana15' />
											<ExcelColumn label='16' value='semana16' />
											<ExcelColumn label='17' value='semana17' />
											<ExcelColumn label='18' value='semana18' />
											<ExcelColumn label='19' value='semana19' />
											<ExcelColumn label='20' value='semana20' />
											<ExcelColumn label='21' value='semana21' />
											<ExcelColumn label='22' value='semana22' />
											<ExcelColumn label='23' value='semana23' />
											<ExcelColumn label='24' value='semana24' />
											<ExcelColumn label='25' value='semana25' />
											<ExcelColumn label='26' value='semana26' />
											<ExcelColumn label='27' value='semana27' />
											<ExcelColumn label='28' value='semana28' />
											<ExcelColumn label='29' value='semana29' />
											<ExcelColumn label='30' value='semana30' />
											<ExcelColumn label='31' value='semana31' />
											<ExcelColumn label='32' value='semana32' />
											<ExcelColumn label='33' value='semana33' />
											<ExcelColumn label='34' value='semana34' />
											<ExcelColumn label='35' value='semana35' />
											<ExcelColumn label='36' value='semana36' />
											<ExcelColumn label='37' value='semana37' />
											<ExcelColumn label='38' value='semana38' />
											<ExcelColumn label='39' value='semana39' />
											<ExcelColumn label='40' value='semana40' />
											<ExcelColumn label='41' value='semana41' />
											<ExcelColumn label='42' value='semana42' />
											<ExcelColumn label='43' value='semana43' />
											<ExcelColumn label='44' value='semana44' />
											<ExcelColumn label='45' value='semana45' />
											<ExcelColumn label='46' value='semana46' />
											<ExcelColumn label='47' value='semana47' />
											<ExcelColumn label='48' value='semana48' />
											<ExcelColumn label='49' value='semana49' />
											<ExcelColumn label='50' value='semana50' />
											<ExcelColumn label='51' value='semana51' />
											<ExcelColumn label='52' value='semana52' />											
										</ExcelSheet>
									</ExcelFile>
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Row>
								<Select
									options={[{ id: 2022, valor: 2022 }, { id: 2023, valor: 2023 }, { id: 2024, valor: 2024 }]}
									label='Ano'
									cols='12 12 3 4'
									placeholder='Selecione o ano'
									value={this.props.filtro.ano}
									onChange={data => {
										this.props.setFiltro({ ...this.props.filtro, ano: data });
									}} />

								<Select
									options={Array.from(Array(52).keys()).map(semana => ({ id: semana + 1, valor: semana + 1 }))}
									label='Semana Inicial'
									cols='12 12 3 4'
									placeholder='Selecione a semana'
									value={this.props.filtro.semana_inicial}
									onChange={data => {
										this.props.setFiltro({ ...this.props.filtro, semana_inicial: data });
									}} />

								<Select
									options={Array.from(Array(52).keys()).map(semana => ({ id: semana + 1, valor: semana + 1 }))}
									label='Semana Final'
									cols='12 12 3 4'
									placeholder='Selecione a semana'
									value={this.props.filtro.semana_final}
									onChange={data => {
										this.props.setFiltro({ ...this.props.filtro, semana_final: data });
									}} />

							</Row>

							<input className='form-control'
								 placeholder='Pesquisar'
								 type='text'
								 value={this.state.pesquisar}
								 onChange={e => {
									 this.setState({ ...this.state, pesquisar: e.target.value });
								 }} />
							
							<div>

							<TableScroll responsive>
								<THead>
									<Tr>
										<Th backgroundColor={'#ffffff'} width={200}>Fornecedor</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={20}>Realizado</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={20}>Status</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={20}>Performance</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={600}>Semanas</Th>
										{/*Array.from(Array(52).keys()).map(semanaAno => (
											<Th key={semanaAno} alignCenter backgroundColor={'#ffffff'} width={10}>{semanaAno}</Th>
										))*/}									
									</Tr>
								</THead>
								<TBody>
									{lista.map((item, i) => {

										let corStatus = item.status > 80 ? '#69f0ae': (
											item.status > 50 ? '#fff64d' : '#ff8a80'
										);

										let corPerformance = item.performance > 80 ? '#69f0ae': (
											item.performance > 50 ? '#fff64d' : '#ff8a80'
										);

										return (
											<Tr key={i} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
												onClick={() => this.setState({
													...this.state,
													linhaSelecionada: item.id,
													fornecedor_selecionado: item
												})}
												onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
												<Td backgroundColor={'#ffffff'} width={200} minWidth={200}>{item.nome_fornecedor.split('-').filter((nome, i) => i > 0).join('-')}</Td>
												<Td alignCenter backgroundColor={'#ffffff'} width={20}>{parseInt(item.realizados || 0)}</Td>
												<Td alignCenter backgroundColor={corStatus} width={20}>{parseInt(item.status || 0)}%</Td>
												<Td alignCenter backgroundColor={corPerformance} width={20}>{parseInt(item.performance || 0)}%</Td>
												<Td alignLeft backgroundColor={'#ffffff'} display={'flex'} paddingTop={10}>
													{Array.from(Array(52).keys()).map(semanaAno => (
														<>{(!this.props.filtro.semana_inicial && !this.props.filtro.semana_final) 
															|| (this.props.filtro.semana_inicial && this.props.filtro.semana_final && semanaAno + 1  >= this.props.filtro.semana_inicial && this.props.filtro.semana_final && semanaAno + 1  <= this.props.filtro.semana_final)
															|| (this.props.filtro.semana_inicial && !this.props.filtro.semana_final && semanaAno + 1  >= this.props.filtro.semana_inicial)
															|| (!this.props.filtro.semana_inicial && this.props.filtro.semana_final && semanaAno + 1  <= this.props.filtro.semana_final) ? (
															<label
																style={{
																	backgroundColor: item.semanas.filter(semana => semana == (semanaAno + 1)).length ? '#13c06b' : (semanaAno > (new Date().getFullYear() == this.props.filtro.ano ? parseInt(item.semana_atual) : 52) ? '#f6eeee' : '#f4c2c2'),
																	color: item.semanas.filter(semana => semana == (semanaAno + 1)).length ? '#ffffff' : '#000000',
																	fontWeight: item.semanas.filter(semana => semana == (semanaAno + 1)).length ? 'bold' : 'normal',
																	width: 30,
																	paddingTop: 2,
																	paddingBottom: 0,
																	marginLeft: 4,
																	textAlign: 'center',
																	fontSize: 12,
																	verticalAlign: 'center'
																}} >{semanaAno + 1}</label>
														) : null}</>
													))}	
												</Td>
											</Tr>
										);
									})}
								</TBody>
							</TableScroll>
							</div>
						</ContentCardBody>
					</ContentCard>
				</Content>
			</div>
		);
	}

	modalInventarios() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>{this.state.fornecedor_selecionado.nome_fornecedor} - Inventários</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, fornecedor_selecionado: null, id_embalagem_selecionada: null })}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>
							<Table responsive>
								<THead>
									<Tr>
										<Th alignCenter>Execução</Th>
										<Th alignCenter>Responsável</Th>
										<Th alignCenter>Observação</Th>
									</Tr>
								</THead>
								<TBody>
									{this.state.fornecedor_selecionado.inventarios.map(item => (
										<Tr key={item.id}>
											<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario)}</Td>
											<Td alignCenter>{item.responsavel}</Td>
											<Td alignCenter>{item.observacao}</Td>
										</Tr>
									))}
								</TBody>
							</Table>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => this.setState({ ...this.state, fornecedor_selecionado: null })}>OK</button>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	filtro: state.dashboardInventarioFornecedor.filtro,
	aguardando: state.dashboardInventarioFornecedor.aguardando,
	lista: state.dashboardInventarioFornecedor.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setFiltro, getLista
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardInventarioFornecedor);
