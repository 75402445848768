import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import NumberFormat from 'react-number-format';
import FormItem from './inventarioItemForm';
import { setError } from '../common/toast/toast';
import pdfAdd from '../assets/images/icone_add_pdf.png';
import pdfAdicionado from '../assets/images/icone_pdf_adicionado.png';
import imagemAdd from '../assets/images/icone_add_imagem.png';

import {
	setModoTela, initForm,
	setModoTelaItem, initFormItem, salvarItens, excluirItem, getListaItem, setListaItem
} from './inventarioActions';

class InventarioForm extends Component {

	state = {

	}

	componentDidMount() {

		const fileSelectorPdfComprovante = document.createElement('input');
		fileSelectorPdfComprovante.setAttribute('type', 'file');
		fileSelectorPdfComprovante.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('application/pdf')
					&& !file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas (.PDF, .PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.pdf_comprovante || []).map(item => item);
					lista.push({
						pdf_comprovante_base64: reader.result
					});

					this.props.initForm({
						...this.props.formularioValues,
						pdf_comprovante: lista
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorPdfComprovante = fileSelectorPdfComprovante;

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir || this.props.visualizacao || (this.props.formularioValues && this.props.formularioValues.id_status && ((this.props.formularioValues.id_status == 2 && !this.props.comprovantes) || this.props.formularioValues.id_status == 3)) ? 'readOnly' : '';

        return (
			<div>
				<ContentCard>
					<Form event={this.props.handleSubmit}>

						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 3'>
									{this.props.excluir ? (
										<Button
											text='Cancelar'
											submit type={'danger'}
											icon={'fa fa-trash'}
											disabled={this.props.visualizacao} />
									) : (!this.props.formularioValues || !this.props.formularioValues.id_status || this.props.formularioValues.id_status == 1 || (this.props.formularioValues.id_status == 2 && this.props.comprovantes) ? (
										<Button
											text='Salvar'
											submit
											type={'success'}
											icon={'fa fa-check'}
											disabled={this.props.visualizacao} />
									) : null)}
								</Grid>
								<Grid cols='6 6 4 3'>
									<Button
										text='Voltar'
										type={'warning'}
										icon={'fa fa-chevron-left'}
										event={() => this.props.setModoTela('lista')} />
								</Grid>
							</Row>
						</ContentCardFooter>

						<ContentCardBody>

							<Row>
								<Field
									name='datahora_inventario'
									component={LabelAndInputMask}
									label='Data/Hora' placeholder='Informe a data e hora da inventário'
									cols='12 6 4 4'
									mask='99/99/9999 99:99'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Select
									name='id_local'
									options={this.props.listaEstoqueLocal}
									label='Local'
									cols='12 12 6 6'
									placeholder='Selecione o local'
									readOnly={this.props.formularioValues.id || this.props.formularioValues.realizado_fornecedor ? 'readOnly' : readOnly}
									value={this.props.formularioValues.id_local}
									onChange={data => {
										if (data) {
											this.props.initForm({
												...this.props.formularioValues,
												id_local: data,
												id_fornecedor: null
											});
										}
									}} />

								{this.props.formularioValues.id_local == 2 ? ( //Se o local da embalagem é o fornecedor
									<Select
										name='id_fornecedor'
										options={this.props.listaFornecedor}
										label='Fornecedor'
										cols='12 12 6 6'
										placeholder='Selecione o fornecedor'
										readOnly={this.props.formularioValues.id || this.props.formularioValues.realizado_fornecedor ? 'readOnly' : readOnly}
										value={this.props.formularioValues.id_fornecedor}
										onChange={data => {
											if (data) {
												this.props.initForm({
													...this.props.formularioValues,
													id_fornecedor: data
												});
											}
										}} />
								) : null}

								<Field
									name='responsavel'
									component={LabelAndInput}
									label='Responsável' placeholder='Informe o responsável'
									cols='12 12 12 12'
									readOnly={readOnly} />

								<Field
									name='observacao'
									component={LabelAndInput}
									label='Observação' placeholder='Informe as observações'
									cols='12 12 12 12'
									readOnly={readOnly} />

							</Row>

							<Row>
								<Grid cols='12 12 12 12'>
									<label>Comprovante</label>
								</Grid>
								{(this.props.formularioValues.pdf_comprovante || []).map(arquivo => (
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span class="btn btn-outline-primary fileinput-button dz-clickable">
											<img src={arquivo.pdf_comprovante_base64 ? pdfAdicionado : (arquivo.pdf ? pdfAdicionado : pdfAdd)} style={{ height: 30 }} />
										</span>
										<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
											<button
												type='button'
												class='btn btn-flat btn-outline-primary'
												style={{ width: '48%' }}
												disabled={arquivo.pdf_comprovante_base64 ?true : false}
												onClick={() => {
													window.open(`https://api-letsarareports.infinitum-ti.com/${arquivo.pdf}`, '_blank');
												}}>
												<i class='fas fa-download'></i>
											</button>

											<button
												type='button'
												class='btn btn-flat btn-outline-danger'
												style={{ width: '48%' }}
												disabled={readOnly}
												onClick={() => {
													this.props.initForm({
														...this.props.formularioValues,
														pdf_comprovante: this.props.formularioValues.pdf_comprovante.filter(item => item != arquivo)
													});
												}}>
												<i class='fas fa-trash-alt'></i>
											</button>
										</div>
									</div>
								))}
								{!readOnly ? (
									<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorPdfComprovante.click()}>
										<img src={pdfAdd} style={{ height: 30 }} />
									</span>
								) : null}
							</Row>

						</ContentCardBody>

					</Form>
				</ContentCard>
				{this.props.formularioValues.id ?
					this.renderItens(readOnly)
					: null
				}
				{this.props.formularioValues.id && this.props.visualizacao ?
					this.renderHistorico()
					: null
				}

			</div>
        )
    }

	renderItens(readOnly) {

		if (this.props.modoTelaItem == 'cadastro') {
			return (
				<FormItem onSubmit={this.props.salvarItem} />
			);
		} else if (this.props.modoTelaItem == 'exclusao') {
			return (
				<FormItem excluir onSubmit={this.props.excluirItem} />
			);
		} else if (this.props.modoTelaItem == 'lista') {
			return (

				<ContentCard>
					<ContentCardHeader>
						<Row>
							<Grid cols='6 6 4 2'>
								{this.props.formularioValues && this.props.formularioValues.id_status == 1 ? (
									<Button
										text='Adicionar'
										type={'success'}
										icon={'fa fa-plus'}
										disabled={readOnly ? true : false}
										event={() => {
											this.props.setModoTelaItem('cadastro', {
												id_inventario: this.props.formularioValues.id
											});
											this.props.initFormItem({
												id_inventario: this.props.formularioValues.id
											});
										}} />
								) : null}
							</Grid>
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<Table responsive>
							<THead>
								<Tr>
									<Th>Embalagem</Th>
									<Th alignCenter width={200}>Quantidade Teórica</Th>
									<Th alignCenter width={200}>Quantidade Vazias</Th>
									<Th alignCenter width={200}>Quantidade Cheias</Th>
									<Th alignCenter width={200}>Quantidade Total</Th>
									<Th></Th>
								</Tr>
							</THead>
							<TBody>
								{this.props.listaItem.map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td>{item.nome_embalagem}</Td>
										<Td alignCenter>{parseInt(item.quantidade_teorica || 0)}</Td>
										<Td alignCenter>
											<NumberFormat className='form-control'
												readOnly={null}
												placeholder={'0'}
												style={{ textAlign: 'center' }}
												decimalSeparator={","} decimalScale={0} fixedDecimalScale={0}
												value={item.quantidade_vazia ? parseInt(item.quantidade_vazia) : ''}
 												onChange={data => {
													this.props.setListaItem(this.props.listaItem.map(itemLista => {
														return itemLista.id == item.id ? { ...itemLista, quantidade_vazia: data.target.value, alterado: true } : itemLista
													}));
 												}} />
										</Td>
										<Td alignCenter>
											<NumberFormat className='form-control'
												readOnly={null}
												placeholder={'0'}
												style={{ textAlign: 'center' }}
												decimalSeparator={","} decimalScale={0} fixedDecimalScale={0}
												value={item.quantidade_cheia ? parseInt(item.quantidade_cheia) : ''}
 												onChange={data => {
													this.props.setListaItem(this.props.listaItem.map(itemLista => {
														return itemLista.id == item.id ? { ...itemLista, quantidade_cheia: data.target.value, alterado: true } : itemLista
													}));
 												}} />
										</Td>
										<Td alignCenter>{parseInt(item.quantidade_vazia || 0) + parseInt(item.quantidade_cheia || 0)}</Td>
									</Tr>
								))}
							</TBody>
						</Table>
						<Button
							text='Salvar Itens'
							type={'success'}
							icon={'fa fa-check'}
							event={() => this.props.salvarItens(this.props.listaItem, this.props.formularioValues.id)}
							disabled={this.props.visualizacao} />
					</ContentCardBody>
				</ContentCard>
			);
		} else {
			return (
				<div></div>
			);
		}
	}

	renderHistorico() {

		return (

			<ContentCard>
				<ContentCardHeader>
					<label>Históricos</label>
				</ContentCardHeader>
				<ContentCardBody>
					<Table responsive>
						<THead>
							<Tr>
								<Th>Data/Hora</Th>
								<Th>Usuário</Th>
								<Th></Th>
							</Tr>
						</THead>
						<TBody>
							{this.props.listaHistorico.map(item => (
								<Tr key={item.id}>
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_alteracao)}</Td>
									<Td>{item.nome_usuario_alteracao}</Td>
									<Td alignRight minWidth={100}>
										<ButtonTable
											type={'success'}
											icon={'fas fa-code-branch'}
											visible={true}
											event={() => {
												this.props.setModoTela('visualizacao', {
													...item.registro,
													datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(item.registro.datahora_inventario),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.registro.datahora_finalizacao)
												});
												this.props.initForm({
													...item.registro,
													datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(item.registro.datahora_inventario),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.registro.datahora_finalizacao)
												});
												console.log(item);
												this.props.setListaItem(item.itens || []);
												window.scrollTo(0, 0);
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);

	}

}

InventarioForm = reduxForm({form: 'inventarioForm', destroyOnUnmount: false})(InventarioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('inventarioForm')(state),
	registro: state.inventario.registro,
	listaItem: state.inventario.listaItem,
	cliente: state.cliente.lista.filter(item => item.id == state.auth.usuario.id_cliente)[0],
	listaTransportadora: state.inventario.listaTransportadora,
	listaFornecedor: state.inventario.listaFornecedor,
	listaEmbalagem: state.inventario.listaEmbalagem,
	listaEstoqueLocal: state.inventario.listaEstoqueLocal,
	listaStatus: state.inventario.listaStatus,
	listaSentido: state.inventario.listaSentido,
	listaEventos: state.inventario.listaEventos,
	listaHistorico: state.inventario.listaHistorico,
	localizacao: state.inventario.localizacao,
	modoTelaItem: state.inventario.modoTelaItem,
	modoTelaEvento: state.inventario.modoTelaEvento
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm,
	setModoTelaItem, initFormItem, salvarItens, excluirItem, getListaItem, setListaItem
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(InventarioForm);
