import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ContentCardFooter from '../common/template/contentCardFooter';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Select from '../common/form/select';
import SelectAlteracao from '../common/form/selectAlteracao';
import { setError } from '../common/toast/toast';
import imagemAdd from '../assets/images/icone_add_imagem.png';

import {
	setModoTela, initForm
} from './embalagemActions';

class EmbalagemForm extends Component {

	state = {

	}

	componentDidMount() {

		const fileSelectorImagem = document.createElement('input');
		fileSelectorImagem.setAttribute('type', 'file');
		fileSelectorImagem.addEventListener("change", (data) => {

			let reader = new FileReader();
			let file = data && data.path && data.path[0] && data.path[0].files && data.path[0].files[0] ? data.path[0].files[0] : null;
			if (!file) {
				file = data && data.target && data.target.files && data.target.files[0] ? data.target.files[0] : null;
			}

			if (file) {

				if (!file.type.includes('image/png') && !file.type.includes('image/jpg') && !file.type.includes('image/jpeg')) {
					setError('Selecione apenas imagens (.PNG, .JPG e .JPEG)');
					return;
				}

				reader.onloadend = () => {

					let lista = (this.props.formularioValues.imagem || []).map(item => item);
					lista.push({
						imagem_base64: reader.result
					});

					let imagemFonte = (this.props.formularioValues.imagem_fonte || []).map(item => item);
					imagemFonte.push('');

					this.props.initForm({
						...this.props.formularioValues,
						imagem: lista,
						imagem_fonte: imagemFonte
					});

				}

				reader.readAsDataURL(file);

			}
		});

		this.fileSelectorImagem = fileSelectorImagem;

	}

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Row>
							<Field
								name='identificador_cliente'
								component={LabelAndInput}
								label='ID Operação' placeholder='Informe o identificador na operação'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome' placeholder='Informe o nome'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='id_tipo'
								component={Select}
								options={this.props.listaTipo}
								label='Tipo' placeholder='Informe o tipo'
								cols='12 12 12 12'
								readOnly={readOnly} />

							<Field
								name='quantidade'
								component={LabelAndInputNumber}
								label='Quantidade' placeholder='Informe a quantidade'
								cols='12 12 4 4'
								casas={0}
								readOnly={readOnly} />

							<Field
								name='largura'
								component={LabelAndInputNumber}
								label='Largura' placeholder='Informe a largura'
								cols='12 12 4 4'
								casas={2}
								readOnly={readOnly} />

							<Field
								name='altura'
								component={LabelAndInputNumber}
								label='Altura' placeholder='Informe a altura'
								cols='12 12 4 4'
								casas={2}
								readOnly={readOnly} />

							<Field
								name='comprimento'
								component={LabelAndInputNumber}
								label='Comprimento' placeholder='Informe a comprimento'
								cols='12 12 4 4'
								casas={2}
								readOnly={readOnly} />

							<Field
								name='peso_bruto'
								component={LabelAndInputNumber}
								label='Peso Bruto' placeholder='Informe a peso bruto'
								cols='12 12 4 4'
								casas={2}
								readOnly={readOnly} />

							<Field
								name='peso_liquido'
								component={LabelAndInputNumber}
								label='Peso Líquido' placeholder='Informe a peso líquido'
								cols='12 12 4 4'
								casas={2}
								readOnly={readOnly} />

							<Field
								name='quantidade_palete'
								component={LabelAndInputNumber}
								label='Quantidade Palete' placeholder='Informe a quantidade palete'
								cols='12 12 4 4'
								casas={0}
								readOnly={readOnly} />
						</Row>

						<Row>
							<Grid cols='12 12 12 12'>
								<label>Fotos</label>
							</Grid>
							{(this.props.formularioValues.imagem || []).map((arquivo, i) => (
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<span class="btn btn-outline-primary fileinput-button dz-clickable">
										<img src={arquivo.imagem_base64 ? arquivo.imagem_base64 : (arquivo.imagem ? `https://api-letsarareports.infinitum-ti.com/${arquivo.imagem}` : imagemAdd)} style={{ height: 30 }} />
									</span>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: 2, marginTop: 4 }}>
										<button
											type='button'
											class='btn btn-flat btn-outline-primary'
											style={{ width: '48%' }}
											disabled={arquivo.imagem_base64 ? true : false}
											onClick={() => {
												window.open(`https://api-letsarareports.infinitum-ti.com/${arquivo.imagem}`, '_blank');
											}}>
											<i class='fas fa-download'></i>
										</button>

										<button
											type='button'
											class='btn btn-flat btn-outline-danger'
											style={{ width: '48%' }}
											onClick={() => {
												this.props.initForm({
													...this.props.formularioValues,
													imagem: this.props.formularioValues.imagem.filter(item => item != arquivo)
												});
											}}>
											<i class='fas fa-trash-alt'></i>
										</button>
									</div>
								</div>
							))}

							<span class="btn btn-outline-primary fileinput-button dz-clickable" onClick={() => this.fileSelectorImagem.click()}>
								<img src={imagemAdd} style={{ height: 30 }} />
							</span>
						</Row>
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols='6 6 4 3'>
								{this.props.excluir ? (
									<Button
										text='Excluir'
										submit type={'danger'}
										icon={'fa fa-trash'} />
								) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
								)}
							</Grid>
							<Grid cols='6 6 4 3'>
								<Button
									text='Voltar'
									type={'warning'}
									icon={'fa fa-chevron-left'}
									event={() => this.props.setModoTela('lista')} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
        )
    }


}

EmbalagemForm = reduxForm({form: 'embalagemForm', destroyOnUnmount: false})(EmbalagemForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	formularioValues: getFormValues('embalagemForm')(state),
	registro: state.embalagem.registro,
	listaTipo: state.embalagem.listaTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EmbalagemForm);
