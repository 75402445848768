import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';

import ReactExport from 'react-export-excel-fixed-xlsx';

import { setFiltro, getLista } from './dashboardInventarioLetsaraActions';
import { getLista as getListaCliente } from '../cliente/clienteActions';

class DashboardInventarioLetsara extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null
	}

    componentWillMount() {
		this.props.getLista();
    }

    render() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let lista = this.props.lista.filter(item => {
			if (this.state.pesquisar
				&& !(`${item.nome_fornecedor || ''}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {

				return false;
			}

			return true;
		}).map((item, i) => {

				let realizados = (item.janeiro ? 1 : 0) + (item.fevereiro ? 1 : 0) +	(item.marco ? 1 : 0) + (item.abril ? 1 : 0) + 
					(item.maio ? 1 : 0) + (item.junho ? 1 : 0) + (item.julho ? 1 : 0) + (item.agosto ? 1 : 0) +	(item.setembro ? 1 : 0) +
					(item.outubro ? 1 : 0) + (item.novembro ? 1 : 0) + (item.dezembro ? 1 : 0);

				return {
					...item,
					realizados: realizados,
					status: parseInt((realizados * 100) / 4)
				};

		});

        return (
            <div>

				<Aguardando aguardando={this.props.aguardando} />

				{this.state.fornecedor_selecionado ? this.modalInventarios() : null}

                <Content>
					<ContentCard>
						<ContentCardHeader>
							<Row>
								<Grid cols='6 6 4 2'>
									<Button
										text='Atualizar'
										type={'primary'}
										icon={'fa fa-plus'}
										event={() => {
											this.props.getLista();
										}} />
								</Grid>
								<Grid cols='6 6 4 2'>
									<ExcelFile
										filename={'Dashboard'}
										element={
											<Button
												text='Exportar'
												type={'primary'}
												icon={'fa fa-plus'} />
										}>
										<ExcelSheet
											data={lista.map(item => ({
												...item,
												nome_fornecedor: item.nome_fornecedor.split('-').filter((nome, i) => i > 0).join('-'),
												janeiro: item.janeiro ? 'OK' : (item.agendado_janeiro ? (item.competencia_atual < parseInt(`${this.props.filtro.ano || new Date().getFullYear()}01`) ? 'AGENDADO' : 'NÃO REALIZADO') : ''),
												fevereiro: item.fevereiro ? 'OK' : (item.agendado_fevereiro ? (item.competencia_atual < parseInt(`${this.props.filtro.ano || new Date().getFullYear()}02`) ? 'AGENDADO' : 'NÃO REALIZADO') : ''),
												marco: item.marco ? 'OK' : (item.agendado_marco ? (item.competencia_atual < parseInt(`${this.props.filtro.ano || new Date().getFullYear()}03`) ? 'AGENDADO' : 'NÃO REALIZADO') : ''),
												abril: item.abril ? 'OK' : (item.agendado_abril ? (item.competencia_atual < parseInt(`${this.props.filtro.ano || new Date().getFullYear()}04`) ? 'AGENDADO' : 'NÃO REALIZADO') : ''),
												maio: item.maio ? 'OK' : (item.agendado_maio ? (item.competencia_atual < parseInt(`${this.props.filtro.ano || new Date().getFullYear()}05`) ? 'AGENDADO' : 'NÃO REALIZADO') : ''),
												junho: item.junho ? 'OK' : (item.agendado_junho ? (item.competencia_atual < parseInt(`${this.props.filtro.ano || new Date().getFullYear()}06`) ? 'AGENDADO' : 'NÃO REALIZADO') : ''),
												julho: item.julho ? 'OK' : (item.agendado_julho ? (item.competencia_atual < parseInt(`${this.props.filtro.ano || new Date().getFullYear()}07`) ? 'AGENDADO' : 'NÃO REALIZADO') : ''),
												agosto: item.agosto ? 'OK' : (item.agendado_agosto ? (item.competencia_atual < parseInt(`${this.props.filtro.ano || new Date().getFullYear()}08`) ? 'AGENDADO' : 'NÃO REALIZADO') : ''),
												setembro: item.setembro ? 'OK' : (item.agendado_setembro ? (item.competencia_atual < parseInt(`${this.props.filtro.ano || new Date().getFullYear()}09`) ? 'AGENDADO' : 'NÃO REALIZADO') : ''),
												outubro: item.outubro ? 'OK' : (item.agendado_outubro ? (item.competencia_atual < parseInt(`${this.props.filtro.ano || new Date().getFullYear()}10`) ? 'AGENDADO' : 'NÃO REALIZADO') : ''),
												novembro: item.novembro ? 'OK' : (item.agendado_novembro ? (item.competencia_atual < parseInt(`${this.props.filtro.ano || new Date().getFullYear()}11`) ? 'AGENDADO' : 'NÃO REALIZADO') : ''),
												dezembro: item.dezembro ? 'OK' : (item.agendado_dezembro ? (item.competencia_atual < parseInt(`${this.props.filtro.ano || new Date().getFullYear()}12`) ? 'AGENDADO' : 'NÃO REALIZADO') : ''),
											}))}

											name='Dashboard'>
											<ExcelColumn label='Fornecedor' value='nome_fornecedor' />
											<ExcelColumn label='Realizado' value='realizados' />
											<ExcelColumn label='Status' value='status' />
											<ExcelColumn label='Jan' value='janeiro' />
											<ExcelColumn label='Fev' value='fevereiro' />
											<ExcelColumn label='Mar' value='marco' />
											<ExcelColumn label='Abr' value='abril' />
											<ExcelColumn label='Mai' value='maio' />
											<ExcelColumn label='Jun' value='junho' />
											<ExcelColumn label='Jul' value='julho' />
											<ExcelColumn label='Ago' value='agosto' />
											<ExcelColumn label='Set' value='setembro' />
											<ExcelColumn label='Out' value='outubro' />
											<ExcelColumn label='Nov' value='novembro' />
											<ExcelColumn label='Dez' value='dezembro' />
										</ExcelSheet>
									</ExcelFile>
								</Grid>
							</Row>
						</ContentCardHeader>
						<ContentCardBody>
							<Row>
								<Select
									options={[{ id: 2022, valor: 2022 }, { id: 2023, valor: 2023 }, { id: 2024, valor: 2024 }]}
									label='Ano'
									cols='12 12 3 4'
									placeholder='Selecione o ano'
									value={this.props.filtro.ano}
									onChange={data => {
										this.props.setFiltro({ ...this.props.filtro, ano: data });
									}} />
							</Row>

							<input className='form-control'
								 placeholder='Pesquisar'
								 type='text'
								 value={this.state.pesquisar}
								 onChange={e => {
									 this.setState({ ...this.state, pesquisar: e.target.value });
								 }} />

							<Table responsive>
								<THead>
									<Tr>
										<Th backgroundColor={'#ffffff'} width={200}>Fornecedor</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={100}>Realizado</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={100}>Status</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={10}>Jan</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={10}>Fev</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={10}>Mar</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={10}>Abr</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={10}>Mai</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={10}>Jun</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={10}>Jul</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={10}>Ago</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={10}>Set</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={10}>Out</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={10}>Nov</Th>
										<Th alignCenter backgroundColor={'#ffffff'} width={10}>Dez</Th>
									</Tr>
								</THead>
								<TBody>
									{lista.map((item, i) => {

										let corStatus = '#ffffff';
										if (new Date().getFullYear() == this.props.filtro.ano) {
											if (new Date().getMonth() < 3) {
												corStatus = item.realizados == 1 ? '#69f0ae' : '#ff8a80';
											} if (new Date().getMonth() < 6) {
												corStatus = item.realizados == 2 ? '#69f0ae' : '#ff8a80';
											} if (new Date().getMonth() < 9) {
												corStatus = item.realizados == 3 ? '#69f0ae' : '#ff8a80';
											} if (new Date().getMonth() < 12) {
												corStatus = item.realizados == 4 ? '#69f0ae' : '#ff8a80';
											}
										} else {
											corStatus = item.realizados == 4 ? '#69f0ae' : '#ff8a80';
										}

										return (
											<Tr key={i} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
												onClick={() => this.setState({
													...this.state,
													linhaSelecionada: item.id,
													fornecedor_selecionado: item
												})}
												onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
												<Td backgroundColor={'#ffffff'} width={150}>{item.nome_fornecedor.split('-').filter((nome, i) => i > 0).join('-')}</Td>
												<Td alignCenter backgroundColor={'#ffffff'} width={100}>{parseInt(item.realizados || 0)}</Td>
												<Td alignCenter backgroundColor={corStatus} width={100}>{parseInt(item.status || 0)}%</Td>
												<Td alignCenter backgroundColor={item.janeiro ? '#69f0ae': (item.agendado_janeiro ? (item.competencia_atual <= parseInt(`${this.props.filtro.ano || new Date().getFullYear()}01`) ? '#fff64d' : '#e84846') : '#ffffff')} width={10}></Td>
												<Td alignCenter backgroundColor={item.fevereiro ? '#69f0ae' : (item.agendado_fevereiro ? (item.competencia_atual <= parseInt(`${this.props.filtro.ano || new Date().getFullYear()}02`) ? '#fff64d' : '#e84846') : '#ffffff')} width={10}></Td>
												<Td alignCenter backgroundColor={item.marco ? '#69f0ae' : (item.agendado_marco ? (item.competencia_atual <= parseInt(`${this.props.filtro.ano || new Date().getFullYear()}03`) ? '#fff64d' : '#e84846') : '#ffffff')} width={10}></Td>
												<Td alignCenter backgroundColor={item.abril ? '#69f0ae' : (item.agendado_abril ? (item.competencia_atual <= parseInt(`${this.props.filtro.ano || new Date().getFullYear()}04`) ? '#fff64d' : '#e84846') : '#ffffff')} width={10}></Td>
												<Td alignCenter backgroundColor={item.maio ? '#69f0ae' : (item.agendado_maio ? (item.competencia_atual <= parseInt(`${this.props.filtro.ano || new Date().getFullYear()}05`) ? '#fff64d' : '#e84846') : '#ffffff')} width={10}></Td>
												<Td alignCenter backgroundColor={item.junho ? '#69f0ae' : (item.agendado_junho ? (item.competencia_atual <= parseInt(`${this.props.filtro.ano || new Date().getFullYear()}06`) ? '#fff64d' : '#e84846') : '#ffffff')} width={10}></Td>
												<Td alignCenter backgroundColor={item.julho ? '#69f0ae' : (item.agendado_julho ? (item.competencia_atual <= parseInt(`${this.props.filtro.ano || new Date().getFullYear()}07`) ? '#fff64d' : '#e84846') : '#ffffff')} width={10}></Td>
												<Td alignCenter backgroundColor={item.agosto ? '#69f0ae' : (item.agendado_agosto ? (item.competencia_atual <= parseInt(`${this.props.filtro.ano || new Date().getFullYear()}08`) ? '#fff64d' : '#e84846') : '#ffffff')} width={10}></Td>
												<Td alignCenter backgroundColor={item.setembro ? '#69f0ae' : (item.agendado_setembro ? (item.competencia_atual <= parseInt(`${this.props.filtro.ano || new Date().getFullYear()}09`) ? '#fff64d' : '#e84846') : '#ffffff')} width={10}></Td>
												<Td alignCenter backgroundColor={item.outubro ? '#69f0ae' : (item.agendado_outubro ? (item.competencia_atual <= parseInt(`${this.props.filtro.ano || new Date().getFullYear()}10`) ? '#fff64d' : '#e84846') : '#ffffff')} width={10}></Td>
												<Td alignCenter backgroundColor={item.novembro ? '#69f0ae' : (item.agendado_novembro ? (item.competencia_atual <= parseInt(`${this.props.filtro.ano || new Date().getFullYear()}11`) ? '#fff64d' : '#e84846') : '#ffffff')} width={10}></Td>
												<Td alignCenter backgroundColor={item.dezembro ? '#69f0ae' : (item.agendado_dezembro ? (item.competencia_atual <= parseInt(`${this.props.filtro.ano || new Date().getFullYear()}12`) ? '#fff64d' : '#e84846') : '#ffffff')} width={10}></Td>
											</Tr>
										);
									})}
								</TBody>
							</Table>
						</ContentCardBody>
					</ContentCard>
				</Content>
			</div>
		);
	}

	modalInventarios() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>{this.state.fornecedor_selecionado.nome_fornecedor} - Inventários</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, fornecedor_selecionado: null, id_embalagem_selecionada: null })}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>
							<Table responsive>
								<THead>
									<Tr>
										<Th alignCenter>Previsão</Th>
										<Th alignCenter>Execução</Th>
										<Th alignCenter>Responsável</Th>
										<Th alignCenter>Observação</Th>
									</Tr>
								</THead>
								<TBody>
									{this.state.fornecedor_selecionado.inventarios.map(item => (
										<Tr key={item.id}>
											<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_previsao)}</Td>
											<Td alignCenter>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario)}</Td>
											<Td alignCenter>{item.responsavel}</Td>
											<Td alignCenter>{item.observacao}</Td>
										</Tr>
									))}
								</TBody>
							</Table>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => this.setState({ ...this.state, fornecedor_selecionado: null })}>OK</button>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	filtro: state.dashboardInventarioLetsara.filtro,
	aguardando: state.dashboardInventarioLetsara.aguardando,
	lista: state.dashboardInventarioLetsara.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setFiltro, getLista
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DashboardInventarioLetsara);
