const INITIAL_STATE = {
	aguardando: false,
	filtro: {
		ano: new Date().getFullYear()
	},
	lista: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

		case 'DASHBOARD_EMBALAGEM_LETSARA_FILTRO':
			return { ...state,
				filtro: action.payload.filtro
			};

		case 'DASHBOARD_EMBALAGEM_LETSARA_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'DASHBOARD_EMBALAGEM_LETSARA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        default:
            return state;
    }
}
