import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setAguardando(aguardando) {
    return {
        type: 'ACUMULADO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getListaSaldo() {
	return (dispatch, getState) => {
		if (getState().auth.usuario) {
			dispatch(setAguardando(true));
	    	axios.get(`${consts.API_URL}/embalagemMovimento?id_cliente=${getState().auth.usuario.id_cliente || 0}`)
			.then(resp => {
				dispatch({
			        type: 'ACUMULADO_SALDO_LISTADO',
			        payload: resp
			    });
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
	}
}

export function getListaViagem() {
	return (dispatch, getState) => {
		if (getState().auth.usuario) {
	    	axios.get(`${consts.API_URL}/embalagemViagem/listarRelatorioGeral?id_cliente=${getState().auth.usuario.id_cliente || 0}&aberta=true&fechada=false`)
			.then(resp => {
				dispatch({
			        type: 'ACUMULADO_EMBALAGEM_VIAGEM_LISTADO',
			        payload: resp
			    });
			}).catch(e => {
				setErroAPI(e);
			});
		}
	}
}
