import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { } from './dashboardActions';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import ValueBox from  '../common/widget/valueBox'
import Row from  '../common/layout/row'
import InfoBox from  '../common/infoBox/infoBox'
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import Button from '../common/button/button';
import ButtonTable from '../common/table/buttonTable';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import ReactExport from 'react-export-excel-fixed-xlsx';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';

import imagemUser from '../assets/images/user.jpg';

import { getListaSaldo, getListaViagem } from './dashboardActions';

class Dashboard extends Component {

	state = {
		pesquisar: '',
		cliente: true,
		fornecedor: true,
		status_verde: true,
		status_vermelho: true,
		id_fornecedor_selecionado: null,
		nome_fornecedor_selecionado: ''
	}

	constructor(props) {
		super(props);
    }

    componentDidMount() {
		this.props.getListaSaldo();
		this.props.getListaViagem();
    }

    render() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		let listaSaldo = this.props.listaSaldo.filter(item => {

			//No modo TRANSPORTADORA só mostra se possui rumo sum ou norte
			if (this.props.sessao && this.props.sessao.modoTransportadora
				&& !parseInt(item.quantidade_rumo_sul) && !parseInt(item.quantidade_rumo_norte)) {
				return false;
			}

			if (this.state.pesquisar
				&& !(`${item.nome_fornecedor} ${item.nome_embalagem}`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
				return false;
			}

			if (this.state.id_tipo_embalagem && item.id_tipo_embalagem != this.state.id_tipo_embalagem) {
				return false;
			}

			if (this.state.cliente && this.state.fornecedor) {
				return true;
			} else if (this.state.cliente) {
				return item.nome_fornecedor == 'Interno';
			} else if (this.state.fornecedor) {
				return item.nome_fornecedor != 'Interno';
			} else {
				return false;
			}
		}).map(item => ({
			...item,
			status_minimo: parseInt(item.saldo) && parseInt(item.minimo) ? parseInt((parseInt(item.saldo) * 100) / parseInt(item.minimo)) - 100 : 0,
			status_maximo: parseInt(item.saldo) && parseInt(item.maximo) ? parseInt((parseInt(item.saldo) * 100) / parseInt(item.maximo)) : 0
		})).filter(item => {

			if (this.state.status_verde && this.state.status_vermelho) {
				return true;
			} else if (this.state.status_verde) {
				return item.status_minimo >= 0;
			} else if (this.state.status_vermelho) {
				return item.status_minimo < 0;
			} else {
				return false;
			}
		});

        return (
			<Content>

				<Aguardando aguardando={this.props.aguardando} />

				{this.state.id_fornecedor_selecionado ? this.modalViagens() : null}

				<ContentCard>
					<ContentCardHeader>
						<Row>
							<Grid cols='6 6 4 2'>
								<Button
									text='Atualizar'
									type={'primary'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.getListaSaldo();
										this.props.getListaViagem();
									}} />
							</Grid>
							<Grid cols='6 6 4 2'>
								<ExcelFile
									filename={'Dashboard'}
									element={
										<Button
											text='Exportar'
											type={'primary'}
											icon={'fa fa-plus'} />
									}>
									{this.props.sessao && this.props.sessao.modoTransportadora ? (
										<ExcelSheet
											data={listaSaldo.map(item => ({
												...item,
												quantidade_rumo_sul: parseInt(item.quantidade_rumo_sul || 0),
												quantidade_rumo_norte: parseInt(item.quantidade_rumo_norte || 0),
												saldo: parseInt(item.saldo || 0),
												minimo: parseInt(item.minimo || 0),
												status_minimo: `${item.status_minimo} %`
											}))}
											name='Dashboard'>
											<ExcelColumn label='Fornecedor' value='nome_fornecedor' />
											<ExcelColumn label='Embalagem' value='nome_embalagem' />
											<ExcelColumn label='RS' value='quantidade_rumo_sul' />
											<ExcelColumn label='RN' value='quantidade_rumo_norte' />
										</ExcelSheet>
									) : (
										<ExcelSheet
											data={listaSaldo.map(item => ({
												...item,
												quantidade_rumo_sul: parseInt(item.quantidade_rumo_sul || 0),
												quantidade_rumo_norte: parseInt(item.quantidade_rumo_norte || 0),
												saldo: parseInt(item.saldo || 0),
												minimo: parseInt(item.minimo || 0),
												status_minimo: `${item.status_minimo} %`
											}))}
											name='Dashboard'>
											<ExcelColumn label='Fornecedor' value='nome_fornecedor' />
											<ExcelColumn label='Embalagem' value='nome_embalagem' />
											<ExcelColumn label='RS' value='quantidade_rumo_sul' />
											<ExcelColumn label='RN' value='quantidade_rumo_norte' />
											<ExcelColumn label='Saldo' value='saldo' />
											<ExcelColumn label='Ideal' value='minimo' />
											<ExcelColumn label='Status' value='status_minimo' />
										</ExcelSheet>
									)}
								</ExcelFile>
							</Grid>
						</Row>
						<br />
						<Row>
							<Select
								options={this.props.listaEmbalagemTipo}
								label='Tipo de Embalagem'
								cols='12 12 3 4'
								placeholder='Selecione o tipo'
								value={this.state.id_tipo_embalagem}
								onChange={data => {
									this.setState({ ...this.state, id_tipo_embalagem: data });
								}} />
						</Row>
						<Row>
							<LabelAndCheckbox
								name='cliente'
								label='Operação'
								cols='6 4 3 2'
								value={this.state.cliente}
								onChange={(data) => {
									this.setState({ ...this.state, cliente: !this.state.cliente });
								}} />

							<LabelAndCheckbox
								name='fornecedor'
								label='Fornecedor'
								cols='6 4 3 2'
								value={this.state.fornecedor}
								onChange={(data) => {
									this.setState({ ...this.state, fornecedor: !this.state.fornecedor });
								}} />

							{this.props.sessao && this.props.sessao.modoTransportadora ? null : (
								<LabelAndCheckbox
									name='status_verde'
									label='Status (Verde)'
									cols='6 4 3 2'
									value={this.state.status_verde}
									onChange={(data) => {
										this.setState({ ...this.state, status_verde: !this.state.status_verde });
									}} />
							)}
							{this.props.sessao && this.props.sessao.modoTransportadora ? null : (
								<LabelAndCheckbox
									name='status_vermelho'
									label='Status (Vermelho)'
									cols='6 4 3 2'
									value={this.state.status_vermelho}
									onChange={(data) => {
										this.setState({ ...this.state, status_vermelho: !this.state.status_vermelho });
									}} />
							)}
						</Row>
					</ContentCardHeader>
					<ContentCardBody>
						<input className='form-control'
			                 placeholder='Pesquisar'
			                 type='text'
							 value={this.state.pesquisar}
							 onChange={e => {
								 this.setState({ ...this.state, pesquisar: e.target.value });
							 }} />
						<Table responsive>
							<THead>
								<Tr>
									<Th></Th>
									<Th></Th>
									<Th alignCenter colspan={2}>Total em Trânsito</Th>
									{this.props.sessao && this.props.sessao.modoTransportadora ? null : (
										<>
											<Th alignCenter></Th>
											<Th alignCenter></Th>
											<Th alignCenter></Th>
										</>
									)}
								</Tr>
								<Tr>
									<Th width={400}>Fornecedor</Th>
									<Th width={200}>Embalagem</Th>
									<Th alignCenter width={40}>RS</Th>
									<Th alignCenter width={40}>RN</Th>
									{this.props.sessao && this.props.sessao.modoTransportadora ? null : (
										<>
											<Th alignCenter width={100}>Saldo</Th>
											<Th alignCenter width={100}>Ideal</Th>
											<Th alignCenter width={50}>Status</Th>
										</>
									)}
								</Tr>
							</THead>
							<TBody>
								{listaSaldo.map(item => ({
									...item,
									status_minimo: parseInt(item.saldo) && parseInt(item.minimo) ? parseInt((parseInt(item.saldo) * 100) / parseInt(item.minimo)) - 100 : 0,
									status_maximo: parseInt(item.saldo) && parseInt(item.maximo) ? parseInt((parseInt(item.saldo) * 100) / parseInt(item.maximo)) : 0
								})).map(item => (
									<Tr key={item.id} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
										onClick={() => this.setState({
											...this.state,
											linhaSelecionada: item.id,
											id_fornecedor_selecionado: item.id_fornecedor,
											nome_fornecedor_selecionado: item.nome_fornecedor,
											id_embalagem_selecionada: item.id_embalagem
										})}
										onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
										<Td>{item.nome_fornecedor}</Td>
										<Td>{item.nome_embalagem}</Td>
										<Td alignCenter>{parseInt(item.quantidade_rumo_sul || 0)}</Td>
										<Td alignCenter>{parseInt(item.quantidade_rumo_norte || 0)}</Td>
										{this.props.sessao && this.props.sessao.modoTransportadora ? null : (
											<>
												<Td alignCenter>{parseInt(item.saldo || 0)}</Td>
												<Td alignCenter>{parseInt(item.minimo || 0)}</Td>
												<Td alignCenter	backgroundColor={item.status_minimo < 0 ? '#ff8a80' : '#69f0ae'} >
													{item.status_minimo} %
												</Td>
											</>
										)}
									</Tr>
								))}
							</TBody>
						</Table>
					</ContentCardBody>
				</ContentCard>
			</Content>
        )
    }

	modalViagens() {

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>{this.state.nome_fornecedor_selecionado} - Viagens Abertas</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setState({ ...this.state, id_fornecedor_selecionado: null, id_embalagem_selecionada: null })}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>
							<Table responsive>
								<THead>
									<Tr>
										<Th>Despacho</Th>
										<Th>Transportadora</Th>
										<Th>Viagem</Th>
										<Th>Solicitação</Th>
										<Th>Fatura</Th>
										<Th>Conhec. Transp.</Th>
										<Th>Cavalo</Th>
										<Th>Carretas</Th>
										<Th>Origem</Th>
										<Th>Destino</Th>
										<Th>Embalagem</Th>
										<Th alignCenter>Quantidade</Th>
									</Tr>
								</THead>
								<TBody>
									{this.props.listaViagem.filter(item =>
										item.id_status == 1
										&& item.id_embalagem == this.state.id_embalagem_selecionada
										&& (item.id_fornecedor_origem == this.state.id_fornecedor_selecionado
										|| item.id_fornecedor_destino == this.state.id_fornecedor_selecionado)
									).map(item => (
										<Tr key={item.id}>
											<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem)}</Td>
											<Td>{item.nome_transportadora}</Td>
											<Td>{item.viagem}</Td>
											<Td>{item.solicitacao}</Td>
											<Td>{item.fatura}</Td>
											<Td>{item.conhecimento_transporte || ''}</Td>
											<Td>{item.cavalo || ''}</Td>
											<Td>{item.carreta1 || ''}{item.carreta2 ? ', ' : ''}{item.carreta2 || ''}</Td>
											<Td>{item.nome_origem == 'Cliente' ? item.nome_cliente : item.nome_fornecedor_origem}</Td>
											<Td>{item.nome_destino == 'Cliente' ? item.nome_cliente : item.nome_fornecedor_destino}</Td>
											<Td>{item.nome_embalagem}</Td>
											<Td alignCenter>{parseInt(item.quantidade)}</Td>
										</Tr>
									))}
								</TBody>
							</Table>
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => this.setState({ ...this.state, id_fornecedor_selecionado: null })}>OK</button>
						</div>
					</div>
				</div>
			</div>
        );
    }
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	aguardando: state.dashboard.aguardando,
	listaSaldo: state.dashboard.listaSaldo,
	listaViagem: state.dashboard.listaViagem,
	listaEmbalagemTipo: state.embalagem.listaTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	getListaSaldo, getListaViagem
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
