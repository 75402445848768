import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	aguardando: false,
	registro: {},
	modoTela: 'lista',
	filtro: {
		aberta: true,
		fechada: false,
		sem_comprovante: true,
		comprovada: false,
		cancelada: false,
		sentido_fornecedor_cliente: true,
		sentido_cliente_fornecedor: true,
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		data_inicial_comprovante: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final_comprovante: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		id_evento: null
	},
	lista: [],
	listaComprovante: [],
	listaTransportadora: [],
	listaFornecedor: [],
	listaEmbalagem: [],
	listaEstoqueLocal: [],
	listaSentido: [],
	listaStatus: [],
	listaEventoTipo: [],
	modoTelaItem: 'lista',
	listaItem: [],
	listaEventos: [],
	listaHistorico: [],
	modoTelaEvento: 'lista',
	localizacao: null,
	listaEventoTipo: [{ id: 1, valor: 'Chegada para Carga' }, { id: 2, valor: 'Início de Viagem' }, { id: 3, valor: 'Chegada na Fronteira' }, { id: 4, valor: 'Saída da Fronteira' }, { id: 5, valor: 'Chegada para Descarga' }, { id: 6, valor: 'Fim de Descarga' }]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'EMBALAGEM_VIAGEM_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'EMBALAGEM_VIAGEM_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

		case 'EMBALAGEM_VIAGEM_FILTRO':
            return { ...state,
				filtro: action.payload.filtro
			};

        case 'EMBALAGEM_VIAGEM_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        case 'EMBALAGEM_VIAGEM_COMPROVANTE_LISTADO':
            return {
				...state,
				listaComprovante: action.payload.data
			};

		case 'EMBALAGEM_VIAGEM_TRANSPORTADORA_SELECT_LISTADO':
			return {
				...state,
				listaTransportadora: action.payload.data
			};

        case 'EMBALAGEM_VIAGEM_FORNECEDOR_SELECT_LISTADO':
			return {
				...state,
				listaFornecedor: action.payload.data
			};

        case 'EMBALAGEM_VIAGEM_EMBALAGEM_SELECT_LISTADO':
			return {
				...state,
				listaEmbalagem: action.payload.data
			};

        case 'EMBALAGEM_VIAGEM_EVENTO_TIPO_SELECT_LISTADO':
			return {
				...state,
				listaEventoTipo: action.payload.data
			};

        case 'EMBALAGEM_VIAGEM_ESTOQUE_LOCAL_SELECT_LISTADO':
			return {
				...state,
				listaEstoqueLocal: action.payload.data
			};

        case 'EMBALAGEM_VIAGEM_SENTIDO_SELECT_LISTADO':
			return {
				...state,
				listaSentido: action.payload.data
			};

        case 'EMBALAGEM_VIAGEM_STATUS_SELECT_LISTADO':
			return {
				...state,
				listaStatus: action.payload.data
			};

        case 'EMBALAGEM_VIAGEM_ITEM_MODO_TELA':
			return { ...state,
				modoTelaItem: action.payload.modo
			};

        case 'EMBALAGEM_VIAGEM_ITEM_LISTADO':
			return {
				...state,
				listaItem: action.payload.data
			};

        case 'EMBALAGEM_VIAGEM_HISTORICO_LISTADO':
			return {
				...state,
				listaHistorico: action.payload.data
			};

		case 'EMBALAGEM_VIAGEM_EVENTO_MODO_TELA':
			return { ...state,
				modoTelaEvento: action.payload.modo
			};

        case 'EMBALAGEM_VIAGEM_EVENTO_LISTADO':
			return {
				...state,
				listaEventos: action.payload.data
			};

        case 'EMBALAGEM_VIAGEM_LOCALIZACAO_LISTADO':
			return {
				...state,
				localizacao: action.payload.data[0]
			};

        default:
            return state;
    }
}
