import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setFiltro(filtro = {}) {
    return {
        type: 'DASHBOARD_EMBALAGEM_LETSARA_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'DASHBOARD_EMBALAGEM_LETSARA_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.get(`${consts.API_URL}/embalagemInventario/listarDashboard?id_cliente=${getState().auth.usuario.id_cliente || 0}
			&id_fornecedor=${getState().auth.usuario.id_fornecedor || null}
			&ano=${getState().dashboardInventarioLetsara.filtro.ano}`)
		.then(resp => {
			dispatch({
		        type: 'DASHBOARD_EMBALAGEM_LETSARA_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}