import React from 'react'

export default props => (
	<td {...props} align={props.alignRight ? 'right' : (props.alignCenter ? 'center' : 'left')} onClick={props.onClick} 
		style={{ 
			paddingLeft: 2, 
			paddingTop: props.paddingTop || 2, 
			paddingRight: 8, 
			paddingBottom: 2, 
			verticalAlign: 'middle', 
			minWidth: props.minWidth, 
			backgroundColor: props.backgroundColor,
			display: props.display
		}}>
		{props.children}
	</td>
)
