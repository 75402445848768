import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import ClienteReducer from '../cliente/clienteReducer';
import EmbalagemReducer from '../embalagem/embalagemReducer';
import FornecedorReducer from '../fornecedor/fornecedorReducer';
import FornecedorEmbalagemReducer from '../fornecedorEmbalagem/fornecedorEmbalagemReducer';
import EmbalagemEntradaReducer from '../embalagemEntrada/embalagemEntradaReducer';
import EmbalagemSaidaMotivoReducer from '../embalagemSaidaMotivo/embalagemSaidaMotivoReducer';
import EmbalagemSaidaReducer from '../embalagemSaida/embalagemSaidaReducer';
import EmbalagemAvariadaReducer from '../embalagemAvariada/embalagemAvariadaReducer';
import EmbalagemViagemReducer from '../embalagemViagem/embalagemViagemReducer';
import EmbalagemViagemRelatorioReducer from '../embalagemViagemRelatorio/embalagemViagemRelatorioReducer';
import EmbalagemViagemRelatorioSaldoReducer from '../embalagemViagemRelatorioSaldo/embalagemViagemRelatorioSaldoReducer';
import EmbalagemInventarioReducer from '../embalagemInventario/embalagemInventarioReducer';
import InventarioReducer from '../inventario/inventarioReducer';
import AcumuladoReducer from '../acumulado/acumuladoReducer';
import TransportadoraReducer from '../transportadora/transportadoraReducer';
import DashboardInventarioLetsaraReducer from '../dashboardInventarioLetsara/dashboardInventarioLetsaraReducer';
import DashboardInventarioFornecedorReducer from '../dashboardInventarioFornecedor/dashboardInventarioFornecedorReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
    dashboard: DashboardReducer,
    usuario: UsuarioReducer,
	cliente: ClienteReducer,
	embalagem: EmbalagemReducer,
	fornecedor: FornecedorReducer,
	fornecedorEmbalagem: FornecedorEmbalagemReducer,
	embalagemEntrada: EmbalagemEntradaReducer,
	embalagemSaidaMotivo: EmbalagemSaidaMotivoReducer,
	embalagemSaida: EmbalagemSaidaReducer,
	embalagemAvariada: EmbalagemAvariadaReducer,
	embalagemViagem: EmbalagemViagemReducer,
	embalagemViagemRelatorio: EmbalagemViagemRelatorioReducer,
	embalagemViagemRelatorioSaldo: EmbalagemViagemRelatorioSaldoReducer,
	embalagemInventario: EmbalagemInventarioReducer,
	inventario: InventarioReducer,
	acumulado: AcumuladoReducer,
	transportadora: TransportadoraReducer,
	dashboardInventarioLetsara: DashboardInventarioLetsaraReducer,
	dashboardInventarioFornecedor: DashboardInventarioFornecedorReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;
