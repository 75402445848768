import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'INVENTARIO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'INVENTARIO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function setFiltro(filtro = {}) {
    return {
        type: 'INVENTARIO_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function initForm(registro = {}) {
    return [
        initialize('inventarioForm', registro)
    ];
}

export function getLista() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.get(`${consts.API_URL}/inventario?
			id_cliente=${getState().auth.usuario.id_cliente || 0}
			&id_fornecedor=${getState().auth.usuario.id_fornecedor || null}
			&data_inicial=${DateFormat.formatarDataTelaParaSql(getState().inventario.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().inventario.filtro.data_inicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(getState().inventario.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().inventario.filtro.data_final)}`)
		.then(resp => {
			dispatch({
		        type: 'INVENTARIO_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function salvar(registro) {
    return (dispatch, getState) => {

		dispatch(setAguardando(true));

		if (!registro.id) {

			registro = {
				...registro,
				id_cliente: getState().auth.usuario.id_cliente,
				id_usuario_alteracao: getState().auth.usuario.id,
				datahora_inventario: registro.datahora_inventario ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_inventario) : null
			};

	        axios.post(`${consts.API_URL}/inventario`, registro).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('cadastro', {
					...registro,
					id: resp.data.id,
					datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_inventario)
				}));
				dispatch(initForm({
					...registro,
					id: resp.data.id,
					datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_inventario)
				}));
				dispatch(getListaItem(resp.data.id));
				dispatch(setModoTelaItem('lista'));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		} else {
			axios.put(`${consts.API_URL}/inventario`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id,
				datahora_inventario: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_inventario)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/inventario?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaFornecedor() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/fornecedor/listarSelect?id_cliente=${getState().auth.usuario.id_cliente || 0}`)
		.then(resp => {
			dispatch({
				type: 'INVENTARIO_FORNECEDOR_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaEmbalagem() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/embalagem/listarSelect?id_cliente=${getState().auth.usuario.id_cliente || 0}`)
		.then(resp => {
			dispatch({
				type: 'INVENTARIO_EMBALAGEM_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaEstoqueLocal() {
    const request = axios.get(`${consts.API_URL}/estoqueLocal/listarSelect`);
    return {
        type: 'INVENTARIO_ESTOQUE_LOCAL_SELECT_LISTADO',
        payload: request
    };
}

export function getListaHistorico(id_inventario) {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/inventario/historico?id_inventario=${id_inventario}`)
		.then(resp => {
			dispatch({
		        type: 'INVENTARIO_HISTORICO_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

//Métodos dos Itens
export function setModoTelaItem(modo, registro = {}) {
    return {
        type: 'INVENTARIO_ITEM_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initFormItem(registro = {}) {
    return [
        initialize('inventarioItemForm', registro)
    ];
}

export function getListaItem(id_inventario) {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/inventarioItem?id_inventario=${id_inventario}`)
		.then(resp => {
			dispatch({
		        type: 'INVENTARIO_ITEM_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function setListaItem(lista) {
	return {
		type: 'INVENTARIO_ITEM_LISTADO',
		payload: { data: lista }
	};
}

export function salvarItens(lista, id_inventario) {
    return async (dispatch, getState) => {
		dispatch(setAguardando(true));
		for (var i = 0; i < lista.length; i++) {
			let registro = lista[i];

			await axios.put(`${consts.API_URL}/inventarioItem`, {
				...registro,
				id_cliente: getState().auth.usuario.id_cliente,
				id_usuario_alteracao: getState().auth.usuario.id,
				quantidade_teorica: String(registro.quantidade_teorica || '').replace(',', '.'),
				quantidade_fisica: String(registro.quantidade_fisica || '').replace(',', '.')
			}).then(resp => {

			}).catch(e => {
				setErroAPI(e);
				console.log(e);
			});
		}
		setSuccess('Operação Realizada com sucesso.');
		dispatch(getListaItem(id_inventario));
		dispatch(setModoTelaItem('lista'));
		dispatch(setAguardando(false));
    };
}

export function excluirItem(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/inventarioItem?id=${registro.id}&id_inventario=${registro.id_inventario}&id_usuario_alteracao=${registro.id_usuario_alteracao}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItem(registro.id_inventario));
			dispatch(setModoTelaItem('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}
