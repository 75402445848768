import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess, setError } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'EMBALAGEM_VIAGEM_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'EMBALAGEM_VIAGEM_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function setFiltro(filtro = {}) {
    return {
        type: 'EMBALAGEM_VIAGEM_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function initForm(registro = {}) {
    return [
        initialize('embalagemViagemForm', registro)
    ];
}

export function getLista() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.get(`${consts.API_URL}/embalagemViagem?
			id_cliente=${getState().auth.usuario.id_cliente || 0}
			&id_transportadora=${getState().auth.usuario.id_transportadora || null}
			&aberta=${getState().embalagemViagem.filtro.aberta || false}
			&fechada=${getState().embalagemViagem.filtro.fechada || false}
			&comprovada=${getState().embalagemViagem.filtro.fechada || false}
			&cancelada=${getState().embalagemViagem.filtro.cancelada || false}
			&data_inicial=${DateFormat.formatarDataTelaParaSql(getState().embalagemViagem.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().embalagemViagem.filtro.data_inicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(getState().embalagemViagem.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().embalagemViagem.filtro.data_final)}`)
		.then(resp => {
			dispatch({
		        type: 'EMBALAGEM_VIAGEM_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaComprovante() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
		axios.get(`${consts.API_URL}/embalagemViagem?
			id_cliente=${getState().auth.usuario.id_cliente || 0}
			&id_transportadora=${getState().auth.usuario.id_transportadora || null}
			&aberta=${true}
			&fechada=${true}
			&comprovada=${true}
			&data_inicial=${DateFormat.formatarDataTelaParaSql(getState().embalagemViagem.filtro.data_inicial_comprovante) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().embalagemViagem.filtro.data_inicial_comprovante)}
			&data_final=${DateFormat.formatarDataTelaParaSql(getState().embalagemViagem.filtro.data_final_comprovante) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().embalagemViagem.filtro.data_final_comprovante)}
			&cancelada=${false}`)
		.then(resp => {
			dispatch({
		        type: 'EMBALAGEM_VIAGEM_COMPROVANTE_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {

			registro = {
				...registro,
				id_cliente: getState().auth.usuario.id_cliente,
				id_usuario_alteracao: getState().auth.usuario.id,
				id_status: 1,
				datahora_viagem: registro.datahora_viagem ? DateFormat.formatarDataHoraTelaParaSql(registro.datahora_viagem) : null
			};

	        axios.post(`${consts.API_URL}/embalagemViagem`, registro).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(getListaComprovante());
				dispatch(setModoTela('cadastro', {
					...registro,
					id: resp.data.id,
					viagem: resp.data.viagem,
					datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_viagem)
				}));
				dispatch(initForm({
					...registro,
					id: resp.data.id,
					conhecimento_transporte: resp.data.conhecimento_transporte,
					cavalo: resp.data.cavalo,
					carreta1: resp.data.carreta1,
					carreta2: resp.data.carreta2,
					viagem: resp.data.viagem,
					datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_viagem)
				}));
				dispatch(getListaItem(resp.data.id));
				dispatch(setModoTelaItem('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/embalagemViagem`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id,
				datahora_viagem: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_viagem)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(getListaComprovante());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function finalizar(registro) {
    return (dispatch, getState) => {

		axios.post(`${consts.API_URL}/embalagemViagem/finalizar`, {
			...registro,
			id_usuario_alteracao: getState().auth.usuario.id,
			id_status: 2,
			datahora_finalizacao: DateFormat.getDataHoraAtual()
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(getListaComprovante());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function finalizarComprovada(registro) {
    return (dispatch, getState) => {

		axios.post(`${consts.API_URL}/embalagemViagem/finalizarComprovada`, {
			...registro,
			id_usuario_alteracao: getState().auth.usuario.id,
			id_status: 3,
			datahora_comprovada: DateFormat.getDataHoraAtual()
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(getListaComprovante());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function reabrir(registro) {
    return (dispatch, getState) => {

		axios.post(`${consts.API_URL}/embalagemViagem/finalizar`, {
			...registro,
			id_usuario_alteracao: getState().auth.usuario.id,
			id_status: 1,
			datahora_finalizacao: null
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(getListaComprovante());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function reabrirComprovada(registro) {
    return (dispatch, getState) => {

		axios.post(`${consts.API_URL}/embalagemViagem/finalizarComprovada`, {
			...registro,
			id_usuario_alteracao: getState().auth.usuario.id,
			id_status: 2,
			datahora_comprovada: null
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(getListaComprovante());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/embalagemViagem?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(getListaComprovante());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaTransportadora() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/transportadora/listarSelect`)
		.then(resp => {
			dispatch({
				type: 'EMBALAGEM_VIAGEM_TRANSPORTADORA_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaFornecedor() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/fornecedor/listarSelect?id_cliente=${getState().auth.usuario.id_cliente || 0}`)
		.then(resp => {
			dispatch({
				type: 'EMBALAGEM_VIAGEM_FORNECEDOR_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaEmbalagem() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/embalagem/listarSelect?id_cliente=${getState().auth.usuario.id_cliente || 0}`)
		.then(resp => {
			dispatch({
				type: 'EMBALAGEM_VIAGEM_EMBALAGEM_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function getListaEstoqueLocal() {
    const request = axios.get(`${consts.API_URL}/estoqueLocal/listarSelect`);
    return {
        type: 'EMBALAGEM_VIAGEM_ESTOQUE_LOCAL_SELECT_LISTADO',
        payload: request
    };
}

export function getListaSentido() {
    const request = axios.get(`${consts.API_URL}/sentido/listarSelect`);
    return {
        type: 'EMBALAGEM_VIAGEM_SENTIDO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaStatus() {
    const request = axios.get(`${consts.API_URL}/embalagemViagemStatus/listarSelect`);
    return {
        type: 'EMBALAGEM_VIAGEM_STATUS_SELECT_LISTADO',
        payload: request
    };
}

export function getListaEmbalagemViagemEventoTipo() {
    const request = axios.get(`${consts.API_URL}/embalagemViagemEventoTipo/listarSelect`);
    return {
        type: 'EMBALAGEM_VIAGEM_EVENTO_TIPO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaHistorico(id_viagem) {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/embalagemViagem/historico?id_viagem=${id_viagem}`)
		.then(resp => {
			dispatch({
		        type: 'EMBALAGEM_VIAGEM_HISTORICO_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

//Métodos dos Itens
export function setModoTelaItem(modo, registro = {}) {
    return {
        type: 'EMBALAGEM_VIAGEM_ITEM_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initFormItem(registro = {}) {
    return [
        initialize('embalagemViagemItemForm', registro)
    ];
}

export function getListaItem(id_viagem) {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/embalagemViagemItem?id_viagem=${id_viagem}`)
		.then(resp => {
			dispatch({
		        type: 'EMBALAGEM_VIAGEM_ITEM_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function setListaItem(lista) {
	return {
		type: 'EMBALAGEM_VIAGEM_ITEM_LISTADO',
		payload: { data: lista }
	};
}

export function salvarItem(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/embalagemViagemItem`, {
				...registro,
				id_cliente: getState().auth.usuario.id_cliente,
				id_usuario_alteracao: getState().auth.usuario.id,
				quantidade: String(registro.quantidade || '').replace(',', '.')
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaItem(registro.id_viagem));
				dispatch(setModoTelaItem('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/embalagemViagemItem`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id,
				quantidade: String(registro.quantidade || '').replace(',', '.')
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaItem(registro.id_viagem));
				dispatch(setModoTelaItem('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirItem(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/embalagemViagemItem?id=${registro.id}&id_viagem=${registro.id_viagem}&id_usuario_alteracao=${registro.id_usuario_alteracao}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItem(registro.id_viagem));
			dispatch(setModoTelaItem('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

//Eventos

export function setModoTelaEvento(modo, registro = {}) {
    return {
        type: 'EMBALAGEM_VIAGEM_EVENTO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initFormEvento(registro = {}) {
    return [
        initialize('embalagemViagemEventoForm', registro)
    ];
}

export function getListaEventos(id_transportadora, id_viagem, solicitacao) {
	return (dispatch, getState) => {

		dispatch({
			type: 'EMBALAGEM_VIAGEM_EVENTO_LISTADO',
			payload: { data: [] }
		});

		// if (id_transportadora == 1) {
	    // 	axios.get(`${consts.API_URL}/embalagemViagem/listarEventos?solicitacao=${solicitacao || 0}`)
		// 	.then(resp => {
		// 		dispatch({
		// 	        type: 'EMBALAGEM_VIAGEM_EVENTO_LISTADO',
		// 	        payload: resp
		// 	    });
		// 	}).catch(e => {
		// 		setErroAPI(e);
		// 	});
		// } else {
			axios.get(`${consts.API_URL}/embalagemViagemEvento?id_viagem=${id_viagem || 0}`)
			.then(resp => {
				dispatch({
			        type: 'EMBALAGEM_VIAGEM_EVENTO_LISTADO',
			        payload: resp
			    });
			}).catch(e => {
				setErroAPI(e);
			});
		// }
	}
}

export function setListaEvento(lista) {
	return {
		type: 'EMBALAGEM_VIAGEM_EVENTO_LISTADO',
		payload: { data: lista }
	};
}

export function salvarEvento(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
			if (parseInt(registro.id_tipo) > 1 && !(getState().embalagemViagem.listaEventos || []).filter(item => item.id_tipo == parseInt(registro.id_tipo) - 1).length) {
				setError('Adicione os eventos de viagem anteriores');
				return;
			}
	        axios.post(`${consts.API_URL}/embalagemViagemEvento`, {
				...registro,
				id_cliente: getState().auth.usuario.id_cliente,
				id_usuario_alteracao: getState().auth.usuario.id,
				datahora_evento: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_evento)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaEventos(registro.id_transportadora, registro.id_viagem));
				dispatch(setModoTelaEvento('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			if (parseInt(registro.id_tipo) > 1 && !(getState().embalagemViagem.listaEventos || []).filter(item => item.id_tipo == parseInt(registro.id_tipo) - 1).length) {
				setError('Adicione os eventos de viagem anteriores');
				return;
			}
			axios.put(`${consts.API_URL}/embalagemViagemEvento`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id,
				datahora_evento: DateFormat.formatarDataHoraTelaParaSql(registro.datahora_evento)
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getListaEventos(registro.id_transportadora, registro.id_viagem));
				dispatch(setModoTelaEvento('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluirEvento(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/embalagemViagemEvento?id=${registro.id}&id_viagem=${registro.id_viagem}&id_usuario_alteracao=${registro.id_usuario_alteracao}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaEventos(registro.id_transportadora, registro.id_viagem));
			dispatch(setModoTelaEvento('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaLocalizacao(solicitacao) {
	return (dispatch, getState) => {

		dispatch({
			type: 'EMBALAGEM_VIAGEM_LOCALIZACAO_LISTADO',
			payload: { data: [] }
		});

    	axios.get(`${consts.API_URL}/embalagemViagem/listarLocalizacao?solicitacao=${solicitacao || 0}`)
		.then(resp => {
			dispatch({
		        type: 'EMBALAGEM_VIAGEM_LOCALIZACAO_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}

export function gerarComprovanteEntrega(id) {

	return (dispatch, getState) => {

		console.log(`${consts.API_URL}/embalagemViagem/comprovanteEntrega?id=${id}`);

		axios.get(`${consts.API_URL}/embalagemViagem/comprovanteEntrega?id=${id}`)
		.then(resp => {
			window.open(`https://api-letsarapremiacao.infinitum-ti.com/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});

	}

}

export function gerarPackingList(id) {

	return (dispatch, getState) => {

		axios.get(`${consts.API_URL}/embalagemViagem/packingList?id=${id}`)
		.then(resp => {
			window.open(`https://api-letsarapremiacao.infinitum-ti.com/${resp.data}`, '_blank');
		}).catch(ex => {
			console.log(ex);
		});

	}

}
