import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout, setCliente } from '../../auth/authActions';
import { getLista as getListaCliente } from '../../cliente/clienteActions';
import { getLista as getListaTransportadora } from '../../transportadora/transportadoraActions';
import { getLista as getListaEmbalagem } from '../../embalagem/embalagemActions';
import { getLista as getListaFornecedor } from '../../fornecedor/fornecedorActions';

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = { open: false };

		this.props.getListaCliente();
		this.props.getListaTransportadora();
	}
	changeOpen() {
		this.setState({ open: !this.state.open });
	}
	render() {
		const { nome, login } = this.props.usuario;

		return (
			<ul className="navbar-nav ml-auto">
				{this.props.usuario ? (
					<select class='form-control'
						onChange={(data => {
							this.props.setCliente(data.target.value  && data.target.value  > 0 ? data.target.value : null);
							this.props.getListaEmbalagem();
							this.props.getListaFornecedor();
						})}
						disabled={this.props.usuario.modoCliente}
						value={this.props.usuario.id_cliente}>
						<option value={0}>Selecionar cliente</option>
						{this.props.listaCliente.map(item => (
							<option key={item.id} value={item.id}>{item.nome_fantasia}</option>
						))}
					</select>
				) : null}
				<li className="nav-item dropdown">
					<a className="nav-link" data-toggle="dropdown" href="#">
						<i className="far fa-user"></i>
					</a>
					<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
						<span className="dropdown-item dropdown-header">Perfil</span>
						<div className="dropdown-divider"></div>
						<a href="#" className="dropdown-item">
							<i className="fas fa-user mr-2"></i> Nome
							<span className="float-right text-muted text-sm">{nome}</span>
						</a>
					<div className="dropdown-divider"></div>
					<a href="#" className="dropdown-item">
						<i className="fas fa-envelope mr-2"></i> Usuário
						<span className="float-right text-muted text-sm">{login}</span>
					</a>
					<a href="#" className="dropdown-item">
						<i className="fas fa-envelope mr-2"></i> {this.props.usuario ? (this.props.usuario.modoTransportadora ? 'Transp.' : (this.props.usuario.modoCliente ? 'Cliente' : 'ADM')) : ''}
						<span className="float-right text-muted text-sm">{this.props.usuario ? (this.props.usuario.modoTransportadora ? this.props.listaTransportadora.filter(item => item.id == this.props.usuario.id_transportadora).map(item => item.nome_fantasia).join('') : (this.props.usuario.modoCliente ? this.props.listaCliente.filter(item => item.id == this.props.usuario.id_cliente).map(item => item.nome_fantasia).join('') : 'Letsara')) : ''}</span>
					</a>
					<div className="dropdown-divider"></div>
						<a href="#" onClick={this.props.logout}
							className="btn btn-block btn-primary btn-sm">Sair</a>
					</div>
				</li>
			</ul>
		);
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario,
	listaCliente: state.cliente.lista,
	listaTransportadora: state.transportadora.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ logout, getListaCliente, setCliente, getListaTransportadora, getListaEmbalagem, getListaFornecedor }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

/*
<div className="navbar-custom-menu">
	<ul className="nav navbar-nav">
		<li onMouseLeave={() => this.changeOpen()}
			className={`dropdown user user-menu ${this.state.open ? 'open' :''}`}>
			<a href="javascript:;" onClick={() => this.changeOpen()}
				aria-expanded={this.state.open ? 'true' : 'false'}
				className="dropdown-toggle"
				data-toggle="dropdown">
				<i className="fa fa-user"/>
				<span className="hidden-xs">{nome}</span>
			</a>
			<ul className="dropdown-menu">
				<li className="user-header">
					<p>{nome}<small>{usuario}</small></p>
				</li>
				<li className="user-footer">
					<div className="pull-right">
						<a href="#" onClick={this.props.logout}
							className="btn btn-default btn-flat">Sair</a>
					</div>
				</li>
			</ul>
		</li>
	</ul>
</div>
*/
