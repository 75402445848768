import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MenuItem from './menuItem'
import MenuTree from './menuTree'
import { withRouter } from 'react-router-dom';

class Menu extends Component {

	constructor(props) {
		super(props);
	}

	render() {

		let selecionado = '';
		if (this.props.location && this.props.location.pathname) {
			selecionado = this.props.location.pathname;
		}

		if (this.props.usuario && this.props.usuario.modoCliente && (
			selecionado == '/usuario' || selecionado == '/cliente' || selecionado == '/transportadora' || selecionado == '/embalagem'
			|| selecionado == '/fornecedor'	|| selecionado == '/fornecedorEmbalagem' || selecionado == '/embalagemEntrada'
			|| selecionado == '/embalagemSaidaMotivo' || selecionado == '/embalagemSaida' || selecionado == '/embalagemAvariada' || selecionado == '/embalagemViagem'
			|| selecionado == '/embalagemInventario' || selecionado == '/embalagemViagemComprovante')) {

			window.location = '/#/dashboard1';
		}
		if (this.props.usuario && this.props.usuario.modoTransportadora && (
			selecionado == '/dashboard1' || selecionado == '/dashboard2' || selecionado == '/usuario' || selecionado == '/cliente' || selecionado == '/transportadora' || selecionado == '/embalagem'
			|| selecionado == '/fornecedor'	|| selecionado == '/fornecedorEmbalagem' || selecionado == '/embalagemEntrada'
			|| selecionado == '/embalagemSaidaMotivo' || selecionado == '/embalagemSaida'
			|| selecionado == '/embalagemInventario' || selecionado == '/acumulado' || selecionado == '/inventario'
			|| selecionado == '/dashboardInventarioLetsara' || selecionado == '/dashboardInventarioFornecedor')) {

			window.location = '/#/embalagemViagem';
		}

		if (this.props.usuario && this.props.usuario.modoFornecedor && (
			selecionado == '/' || selecionado == '/usuario' || selecionado == '/cliente' || selecionado == '/transportadora'
			|| selecionado == '/embalagem'	|| selecionado == '/embalagemConsulta' || selecionado == '/fornecedor'
			|| selecionado == '/fornecedorConsulta' || selecionado == '/fornecedorEmbalagem' || selecionado == '/embalagemEntrada'
			|| selecionado == '/embalagemSaidaMotivo' || selecionado == '/embalagemSaida' || selecionado == '/embalagemViagem'
			|| selecionado == '/embalagemViagemComprovante'
			|| selecionado == '/acumulado' || selecionado == '/embalagemInventario' || selecionado == '/dashboardInventarioLetsara')) {

			window.location = '/#/inventario';
		}

		return (
			<nav className="mt-2">
				{this.props.usuario && !this.props.usuario.modoCliente && !this.props.usuario.modoTransportadora && !this.props.usuario.modoFornecedor ? (
					<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
						<MenuItem path='dashboard1' label='Dashboard' icon='fas fa-tachometer-alt' active={selecionado == '/dashboard1'} />
						<MenuItem path='dashboardInventarioLetsara' label='Inventário Letsara' icon='fas fa-calendar' active={selecionado == '/dashboardInventarioLetsara'} />
						<MenuItem path='dashboardInventarioFornecedor' label='Inventário Fornecedor' icon='fas fa-calendar-week' active={selecionado == '/dashboardInventarioFornecedor'} />
						<MenuItem path='usuario' label='Usuário' icon='fas fa-users' active={selecionado == '/usuario'} />
						<MenuItem path='cliente' label='Operação' icon='fas fa-user-circle' active={selecionado == '/cliente'} />
						<MenuItem path='transportadora' label='Transportadora' icon='fas fa-truck-moving' active={selecionado == '/transportadora'} />
						<MenuItem path='embalagem' label='Embalagem' icon='fas fa-th' active={selecionado == '/embalagem'} />
						<MenuItem path='embalagemConsulta' label='Consulta Embalagens' icon='fas fa-th-large' active={selecionado == '/embalagemConsulta'} />
						<MenuItem path='fornecedor' label='Fornecedor' icon='fas fa-user-plus' active={selecionado == '/fornecedor'} />
						<MenuItem path='fornecedorConsulta' label='Consulta Fornecedores' icon='fas fa-th-large' active={selecionado == '/fornecedorConsulta'} />
						<MenuItem path='fornecedorEmbalagem' label='Fornecedor Embalagem' icon='fas fa-project-diagram' active={selecionado == '/fornecedorEmbalagem'} />
						<MenuItem path='embalagemEntrada' label='Entrada' icon='fa fa-caret-square-up' active={selecionado == '/embalagemEntrada'} />
						<MenuItem path='embalagemSaidaMotivo' label='Motivo de Baixa' icon='fas fa-list-alt' active={selecionado == '/embalagemSaidaMotivo'} />
						<MenuItem path='embalagemSaida' label='Saída' icon='fas fa-caret-square-down' active={selecionado == '/embalagemSaida'} />
						<MenuItem path='embalagemAvariada' label='Avariada' icon='fas fa-dumpster-fire' active={selecionado == '/embalagemAvariada'} />
						<MenuItem path='embalagemViagem' label='Viagem' icon='fas fa-truck' active={selecionado == '/embalagemViagem'} />
						<MenuItem path='embalagemViagemComprovante' label='Gestão de Comprovantes' icon='fas fa-paste' active={selecionado == '/embalagemViagemComprovante'} />
						<MenuItem path='embalagemViagemRelatorio' label='Relatório de Viagem' icon='fas fa-list' active={selecionado == '/embalagemViagemRelatorio'} />
						<MenuItem path='embalagemInventario' label='Inventário (Letsara)' icon='fas fa-clipboard-list' active={selecionado == '/embalagemInventario'} />
						<MenuItem path='inventario' label='Inventário' icon='fas fa-clipboard-list' active={selecionado == '/inventario'} />
						<MenuItem path='/acumulado' label='Total Acumulado RN-RS' icon='fas fa-bars' active={selecionado == '/acumulado'} />
			    	</ul>
				) : (this.props.usuario && this.props.usuario.modoTransportadora ? (
					<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
						<MenuItem path='/embalagemViagem' label='Viagem' icon='fas fa-truck' active={selecionado == '/embalagemViagem'} />
						<MenuItem path='embalagemViagemComprovante' label='Gestão de Comprovantes' icon='fas fa-paste' active={selecionado == '/embalagemViagemComprovante'} />
						<MenuItem path='embalagemConsulta' label='Consulta Embalagens' icon='fas fa-th-large' active={selecionado == '/embalagemConsulta'} />
						<MenuItem path='fornecedorConsulta' label='Consulta Fornecedores' icon='fas fa-th-large' active={selecionado == '/fornecedorConsulta'} />
						<MenuItem path='embalagemViagemRelatorio' label='Relatório de Viagem' icon='fas fa-list' active={selecionado == '/embalagemViagemRelatorio'} />
					</ul>
				) : (this.props.usuario && this.props.usuario.modoFornecedor ? (
					<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
						<MenuItem path='inventario' label='Inventário' icon='fas fa-clipboard-list' active={selecionado == '/inventario'} />
						<MenuItem path='embalagemViagemRelatorio' label='Relatório de Viagem' icon='fas fa-list' active={selecionado == '/embalagemViagemRelatorio'} />
						<MenuItem path='dashboardInventarioFornecedor' label='Inventário Fornecedor' icon='fas fa-calendar-week' active={selecionado == '/dashboardInventarioFornecedor'} />
			    	</ul>
				) : (
					<ul className="nav nav-pills nav-sidebar flex-column nav-flat nav-compact" data-widget="treeview" role="menu" data-accordion="false">
						<MenuItem path='dashboard1' label='Dashboard' icon='fas fa-tachometer-alt' active={selecionado == '/dashboard1'} />
						<MenuItem path='dashboardInventarioLetsara' label='Inventário Letsara' icon='fas fa-calendar' active={selecionado == '/dashboardInventarioLetsara'} />
						<MenuItem path='dashboardInventarioFornecedor' label='Inventário Fornecedor' icon='fas fa-calendar-week' active={selecionado == '/dashboardInventarioFornecedor'} />
						<MenuItem path='/acumulado' label='Total Acumulado RN-RS' icon='fas fa-bars' active={selecionado == '/acumulado'} />
						<MenuItem path='embalagemConsulta' label='Consulta Embalagens' icon='fas fa-th-large' active={selecionado == '/embalagemConsulta'} />
						<MenuItem path='fornecedorConsulta' label='Consulta Fornecedores' icon='fas fa-th-large' active={selecionado == '/fornecedorConsulta'} />
						<MenuItem path='embalagemViagemRelatorio' label='Relatório de Viagem' icon='fas fa-list' active={selecionado == '/embalagemViagemRelatorio'} />
						<MenuItem path='inventario' label='Inventário' icon='fas fa-clipboard-list' active={selecionado == '/inventario'} />
			    	</ul>
				)))}
			</nav>
		);
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario
});
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
