import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'TRANSPORTADORA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('transportadoraForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/transportadora`);
    return {
        type: 'TRANSPORTADORA_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/transportadora`, {
				...registro,
				cnpj: String(registro.cnpj || '').split(".").join("").replace("-", "").replace("/", ""),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/transportadora`, {
				...registro,
				cnpj: String(registro.cnpj || '').split(".").join("").replace("-", "").replace("/", ""),
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/transportadora?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaEstoqueLocal() {
    const request = axios.get(`${consts.API_URL}/estoqueLocal/listarSelect`);
    return {
        type: 'TRANSPORTADORA_ESTOQUE_LOCAL_SELECT_LISTADO',
        payload: request
    };
}

//FORNECEDOR

export function adicionarFornecedor(registro) {
    return (dispatch, getState) => {
		axios.post(`${consts.API_URL}/transportadoraFornecedor`, {
			...registro,
			id_usuario_alteracao: getState().auth.usuario.id
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaTransportadoraFornecedor(registro.id_transportadora));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function removerFornecedor(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/transportadoraFornecedor?id=${registro.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaTransportadoraFornecedor(registro.id_transportadora));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaTransportadoraFornecedor(id_transportadora) {
    const request = axios.get(`${consts.API_URL}/transportadoraFornecedor?id_transportadora=${id_transportadora || 0}`);
    return {
        type: 'TRANSPORTADORA_FORNECEDOR_LISTADO',
        payload: request
    };
}

export function getListaFornecedor() {
	return (dispatch, getState) => {
    	axios.get(`${consts.API_URL}/fornecedor/listarSelect?id_cliente=${getState().auth.usuario.id_cliente || 0}`)
		.then(resp => {
			dispatch({
				type: 'TRANSPORTADORA_FORNECEDOR_SELECT_LISTADO',
		        payload: resp
		    });
		}).catch(e => {
			setErroAPI(e);
		});
	}
}
