const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaEstoqueLocal: [],
	listaFornecedor: [],
	listaTransportadoraFornecedor: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'TRANSPORTADORA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'TRANSPORTADORA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        case 'TRANSPORTADORA_ESTOQUE_LOCAL_SELECT_LISTADO':
			return {
				...state,
				listaEstoqueLocal: action.payload.data
			};

        case 'TRANSPORTADORA_FORNECEDOR_LISTADO':
			return {
				...state,
				listaTransportadoraFornecedor: action.payload.data
			};

        case 'TRANSPORTADORA_FORNECEDOR_SELECT_LISTADO':
		console.log(action.payload.data);
			return {
				...state,
				listaFornecedor: action.payload.data
			};

		default:
			return state;

		}
    }
