import React from 'react'

export default props => (
	<button type={'button'} className={`btn btn-flat btn-outline-${props.type}`}
		title={props.title}
		onClick={props.event} disabled={props.disabled}
		style={{ visibility: props.visible ? 'visible' : 'collapse' }}>
		<i className={props.icon}></i>
	</button>
)

/*
primary
secondary
success
danger
warning
info
light
dark
link
*/
