import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './embalagemViagemForm';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import {
	setModoTela, setFiltro, initForm, salvar, excluir, getLista, finalizar, reabrir, gerarComprovanteEntrega, gerarPackingList,
	setModoTelaItem, initFormItem, salvarItem, excluirItem, getListaItem, getListaHistorico,
	getListaFornecedor, getListaEmbalagem, getListaEstoqueLocal, getListaSentido, getListaStatus, getListaTransportadora, getListaEmbalagemViagemEventoTipo } from './embalagemViagemActions';
import { getLista as getListaCliente } from '../cliente/clienteActions';

class EmbalagemViagem extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaTransportadora();
		this.props.getListaCliente();
		this.props.getListaFornecedor();
		this.props.getListaEmbalagem();
		this.props.getListaEstoqueLocal();
		this.props.getListaSentido();
		this.props.getListaStatus();
		this.props.getListaEmbalagemViagemEventoTipo();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'visualizacao' ? (
						<Form onSubmit={() => {}} visualizacao />
					) : null}
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Adicionar'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {
										datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
										id_transportadora: this.props.sessao.id_transportadora
									});
									this.props.initForm({
										datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
										id_transportadora: this.props.sessao.id_transportadora
									});
									this.props.getListaItem(0);
									this.props.getListaHistorico(0);
									this.props.setModoTelaItem('lista');
								}} />
						</Grid>
						<Grid cols='6 6 4 2'>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista();
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<LabelAndInputMask
							label='Data/Hora' placeholder='Informe o período inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
								this.props.getLista();
							}} />

						<LabelAndInputMask
							label='Data/Hora' placeholder='Informe o período final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
								this.props.getLista();
							}} />

						<Select
							options={this.props.listaEventoTipo}
							label='Evento'
							cols='12 12 3 4'
							placeholder='Selecione o evento'
							value={this.props.filtro.id_evento}
							onChange={data => {
								this.props.setFiltro({ ...this.props.filtro, id_evento: data });
							}} />

						{/*<Select
							options={this.props.listaEmbalagemTipo}
							label='Tipo de Embalagem'
							cols='12 12 3 4'
							placeholder='Selecione o tipo'
							value={this.props.filtro.id_tipo_embalagem}
							onChange={data => {
								this.props.setFiltro({ ...this.props.filtro, id_tipo_embalagem: data });
							}} />*/}
					</Row>

					<Row>
						<LabelAndCheckbox
							name='aberta'
							label='Abertas'
							cols='6 4 3 2'
							value={this.props.filtro.aberta}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, aberta: !this.props.filtro.aberta });
								this.props.getLista();
							}} />

						<LabelAndCheckbox
							name='fechada'
							label='Finalizadas'
							cols='6 4 3 2'
							value={this.props.filtro.fechada}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, fechada: !this.props.filtro.fechada });
								this.props.getLista();
							}} />

						<LabelAndCheckbox
							name='cancelada'
							label='Canceladas'
							cols='6 4 3 2'
							value={this.props.filtro.cancelada}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, cancelada: !this.props.filtro.cancelada });
								this.props.getLista();
							}} />

						<LabelAndCheckbox
							name='sentido_fornecedor_cliente'
							label='Fornecedor > Cliente'
							cols='6 4 3 2'
							value={this.props.filtro.sentido_fornecedor_cliente}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, sentido_fornecedor_cliente: !this.props.filtro.sentido_fornecedor_cliente });
							}} />

						<LabelAndCheckbox
							name='sentido_cliente_fornecedor'
							label='Cliente > Fornecedor'
							cols='6 4 3 2'
							value={this.props.filtro.sentido_cliente_fornecedor}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, sentido_cliente_fornecedor: !this.props.filtro.sentido_cliente_fornecedor });
							}} />
					</Row>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />

					<Table responsive>
						<THead>
							<Tr>
								<Th>Data/Hora</Th>
								<Th>Transportadora</Th>
								<Th>Status</Th>
								<Th>Viagem</Th>
								<Th>Solicitação</Th>
								<Th>Fatura</Th>
								<Th>Conhec. Transp.</Th>
								<Th>Embarque GMA</Th>
								<Th>Cavalo</Th>
								<Th>Carretas</Th>
								<Th>Origem</Th>
								<Th>Destino</Th>
								<Th>Último Status</Th>
								<Th width={this.props.sessao && !this.props.sessao.modoCliente && !this.props.sessao.modoTransportadora && !this.props.sessao.modoFornecedor ? 280 : 240}></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {

								if (this.props.filtro.sentido_fornecedor_cliente && this.props.filtro.sentido_cliente_fornecedor){
									//continue
								} else if (this.props.filtro.sentido_fornecedor_cliente && item.id_origem == 2) {
									//continue
								} else if (this.props.filtro.sentido_cliente_fornecedor && item.id_origem == 1) {
									//continue
								} else {
									return false;
								}

								if (this.props.filtro.id_evento && item.id_ultimo_evento != this.props.filtro.id_evento) {
									return false;
								}

								if (this.props.filtro.id_tipo_embalagem && item.id_tipo_embalagem != this.props.filtro.id_tipo_embalagem) {
									return false;
								}

								if (this.state.pesquisar
									&& !(`${DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem)} ${item.nome_transportadora} ${item.nome_status} ${item.viagem} ${item.solicitacao}
										${item.fatura} ${item.conhecimento_transporte || ''} ${item.carga_manual || ''} ${item.cavalo || ''} ${item.carreta1 || ''} ${item.carreta2 || ''}
										${item.nome_origem}	${item.nome_fornecedor_origem} ${item.nome_destino}	${item.nome_fornecedor_destino} ${item.ultimo_evento}
									`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map((item, i) => (
								<Tr key={i} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem)}</Td>
									<Td>{item.nome_transportadora}</Td>
									<Td>{item.ativo ? item.nome_status : 'Cancelada'}</Td>
									<Td>{item.viagem}</Td>
									<Td>{item.solicitacao}</Td>
									<Td>{item.fatura}</Td>
									<Td>{item.conhecimento_transporte || ''}</Td>
									<Td>{item.carga_manual || ''}</Td>
									<Td>{item.cavalo || ''}</Td>
									<Td>{item.carreta1 || ''}{item.carreta2 ? ', ' : ''}{item.carreta2 || ''}</Td>
									<Td>{item.nome_fornecedor_origem || item.nome_cliente}</Td>
									<Td>{item.nome_fornecedor_destino || item.nome_cliente}</Td>
									<Td>{item.ultimo_evento || ''}</Td>
									<Td alignRight minWidth={this.props.sessao && !this.props.sessao.modoCliente && !this.props.sessao.modoTransportadora && !this.props.sessao.modoFornecedor ? 280 : 240}>
										<ButtonTable
											type={'primary'}
											icon={'fas fa-eye'}
											visible={this.state.linhaSelecionada == item.id}
											title='Visualizar'
											event={() => {
												this.props.setModoTela('visualizacao', {
													...item,
													datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao)
												});
												this.props.initForm({
													...item,
													datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao)
												});

												this.props.getListaItem(item.id);
												this.props.getListaHistorico(item.id);
												this.props.setModoTelaItem('lista');
											}} />

										<ButtonTable
											type={'warning'}
											icon={'fas fa-pencil-alt'}
											disabled={!item.ativo}
											visible={this.state.linhaSelecionada == item.id}
											title='Editar'
											event={() => {
												this.props.setModoTela('cadastro', {
													...item,
													datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao)
												});
												this.props.initForm({
													...item,
													datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao)
												});

												this.props.getListaItem(item.id);
												this.props.setModoTelaItem('lista');
											}} />

										<ButtonTable
											type={'danger'}
											icon={'fas fa-trash-alt'}
											visible={this.state.linhaSelecionada == item.id}
											disabled={item.id_status != 1}
											title='Cancelar'
											event={() => {
												this.props.setModoTela('exclusao', {
													...item,
													datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao)
												});
												this.props.initForm({
													...item,
													datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao)
												});

												this.props.getListaItem(item.id);
												this.props.setModoTelaItem('lista');
											}} />

										<ButtonTable
											type={'primary'}
											icon={'fas fa-file-alt'}
											visible={this.state.linhaSelecionada == item.id}
											title='Emitir comprovante de entrega'
											event={() => {
												this.props.gerarComprovanteEntrega(item.id);
											}} />

										{this.props.sessao && !this.props.sessao.modoCliente && !this.props.sessao.modoTransportadora && !this.props.sessao.modoFornecedor ? (
											<ButtonTable
												type={'primary'}
												icon={'fas fa-file-invoice'}
												visible={this.state.linhaSelecionada == item.id}
												title='Emitir Packing List'
												event={() => {
													this.props.gerarPackingList(item.id);
												}} />
										) : null}

										<ButtonTable
											type={'success'}
											icon={`fas fa-${item.id_status == 1 ? 'check' : 'undo'}`}
											visible={this.state.linhaSelecionada == item.id}
											disabled={item.id_status == 3}
											title={item.id_status == 1 ? 'Finalizar viagem' : 'Reabrir viagem'}
											event={() => {
												if (item.id_status == 1) {
													this.props.finalizar(item);
												} else if (item.id_status == 2) {
													this.props.reabrir(item);
												}
											}} />
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	aguardando: state.embalagemViagem.aguardando,
	filtro: state.embalagemViagem.filtro,
	modoTela: state.embalagemViagem.modoTela,
	filtro: state.embalagemViagem.filtro,
	lista: state.embalagemViagem.lista,
	listaItem: state.embalagemViagem.listaItem,
	listaFornecedor: state.embalagemViagem.listaFornecedor,
	listaEmbalagem: state.embalagemViagem.listaEmbalagem,
	listaEstoqueLocal: state.embalagemViagem.listaEstoqueLocal,
	listaEventoTipo: state.embalagemViagem.listaEventoTipo,
	listaEmbalagemTipo: state.embalagem.listaTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, setFiltro, initForm, salvar, excluir, getLista, finalizar, reabrir, gerarComprovanteEntrega, gerarPackingList,
	setModoTelaItem, initFormItem, salvarItem, excluirItem, getListaItem, getListaHistorico,
	getListaFornecedor, getListaEmbalagem, getListaEstoqueLocal, getListaSentido, getListaStatus, getListaCliente, getListaTransportadora, getListaEmbalagemViagemEventoTipo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EmbalagemViagem);
