import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './inventarioForm';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Aguardando from '../common/template/aguardando';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import {
	setModoTela, setFiltro, initForm, salvar, excluir, getLista,
	setModoTelaItem, initFormItem, excluirItem, getListaItem, setListaItem, getListaHistorico,
	getListaFornecedor, getListaEmbalagem, getListaEstoqueLocal } from './inventarioActions';
import { getLista as getListaCliente } from '../cliente/clienteActions';

class Inventario extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
		this.props.getListaFornecedor();
		this.props.getListaEmbalagem();
		this.props.getListaEstoqueLocal();
    }

	componentDidMount() {
		var idInterval = setInterval(() => {
			if (this.props.sessao.id_cliente && this.props.lista.length == 0) {
				this.props.getLista();
				clearInterval(idInterval);
			}
		}, 10000);
	}

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.props.modoTela == 'visualizacao' ? (
						<Form onSubmit={() => {}} visualizacao />
					) : null}
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							{!this.props.sessao.modoCliente ? (
								<Button
									text='Adicionar'
									type={'success'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.setModoTela('cadastro', {
											datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
											realizado_fornecedor: this.props.sessao.modoFornecedor || false,
											id_local: this.props.sessao.modoFornecedor ? 2 : null, //Se é modo fornecedor já inicializa com o local fornecedor
											id_fornecedor: this.props.sessao.modoFornecedor ? this.props.sessao.id_fornecedor : null //Se é modo fornecedor já inicializa com o fornecedor
										});
										this.props.initForm({
											datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(DateFormat.getDataHoraAtual()),
											realizado_fornecedor: this.props.sessao.modoFornecedor || false,
											id_local: this.props.sessao.modoFornecedor ? 2 : null, //Se é modo fornecedor já inicializa com o local fornecedor
											id_fornecedor: this.props.sessao.modoFornecedor ? this.props.sessao.id_fornecedor : null //Se é modo fornecedor já inicializa com o fornecedor
										});
										this.props.getListaItem(0);
										this.props.getListaHistorico(0);
										this.props.setModoTelaItem('lista');
									}} />
							) : null}
						</Grid>
						<Grid cols='6 6 4 2'>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getLista();
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<LabelAndInputMask
							label='Data Incial' placeholder='Informe a data'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial}
							onChange={data => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
								this.props.getLista();
							}} />

						<LabelAndInputMask
							label='Data Final' placeholder='Informe a data'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final}
							onChange={data => {
								this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
								this.props.getLista();
							}} />
					</Row>
					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />

					<Table responsive>
						<THead>
							<Tr>
								<Th>Data/Hora</Th>
								<Th>Local</Th>
								<Th>Fornecedor</Th>
								<Th>Responsável</Th>
								<Th>Status</Th>
								<Th width={100}></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {
								if (this.state.pesquisar
									&& !(`${DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario)} ${item.nome_local} ${item.nome_fornecedor} ${item.responsavel} ${item.ativo ? 'Ativo' : 'Inativo'}
									`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								return true;
							}).map((item, i) => (
								<Tr key={i} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario)}</Td>
									<Td>{item.nome_local}</Td>
									<Td>{item.nome_fornecedor}</Td>
									<Td>{item.responsavel}</Td>
									<Td>{item.ativo ? 'Ativo' : 'Inativo'}</Td>
									<Td alignRight minWidth={250}>
										<ButtonTable
											type={'primary'}
											icon={'fas fa-eye'}
											visible={this.state.linhaSelecionada == item.id}
											title='Visualizar'
											event={() => {
												this.props.setModoTela('visualizacao', {
													...item,
													datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario)
												});
												this.props.initForm({
													...item,
													datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario)
												});

												this.props.getListaItem(item.id);
												this.props.getListaHistorico(item.id);
												this.props.setModoTelaItem('lista');
											}} />
											
										{!this.props.sessao.modoCliente ? (
											<ButtonTable
												type={'warning'}
												icon={'fas fa-pencil-alt'}
												visible={this.state.linhaSelecionada == item.id}
												title='Editar'
												disabled={!item.ativo}
												event={() => {
													this.props.setModoTela('cadastro', {
														...item,
														datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario)
													});
													this.props.initForm({
														...item,
														datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario)
													});

													this.props.getListaItem(item.id);
													this.props.setModoTelaItem('lista');
												}} />
										) : null}
										{!this.props.sessao.modoCliente ? (
											<ButtonTable
												type={'danger'}
												icon={'fas fa-trash-alt'}
												visible={this.state.linhaSelecionada == item.id}
												disabled={!item.ativo}
												title='Excluir'
												event={() => {
													this.props.setModoTela('exclusao', {
														...item,
														datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario)
													});
													this.props.initForm({
														...item,
														datahora_inventario: DateFormat.formatarDataHoraSqlParaTela(item.datahora_inventario)
													});

													this.props.getListaItem(item.id);
													this.props.setModoTelaItem('lista');
												}} />
										) : null}
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	aguardando: state.inventario.aguardando,
	filtro: state.inventario.filtro,
	modoTela: state.inventario.modoTela,
	filtro: state.inventario.filtro,
	lista: state.inventario.lista,
	listaItem: state.inventario.listaItem,
	listaFornecedor: state.inventario.listaFornecedor,
	listaEmbalagem: state.inventario.listaEmbalagem,
	listaEstoqueLocal: state.inventario.listaEstoqueLocal
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, setFiltro, initForm, salvar, excluir, getLista,
	setModoTelaItem, initFormItem, excluirItem, getListaItem, setListaItem, getListaHistorico,
	getListaFornecedor, getListaEmbalagem, getListaEstoqueLocal
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Inventario);
