import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	aguardando: false,
	registro: {},
	modoTela: 'lista',
	filtro: {
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual())
	},
	lista: [],
	listaTipo: [{ id: 1, valor: 'Baixa' }, { id: 2, valor: 'Inventário' }],
	listaFornecedor: [],
	listaEmbalagem: [],
	listaEstoqueLocal: [],
	listaMotivo: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'EMBALAGEM_SAIDA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

		case 'EMBALAGEM_SAIDA_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

        case 'EMBALAGEM_SAIDA_FILTRO':
            return {
				...state,
				filtro: action.payload.filtro
			};

        case 'EMBALAGEM_SAIDA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        case 'EMBALAGEM_SAIDA_FORNECEDOR_SELECT_LISTADO':
			return {
				...state,
				listaFornecedor: action.payload.data
			};

        case 'EMBALAGEM_SAIDA_EMBALAGEM_SELECT_LISTADO':
			return {
				...state,
				listaEmbalagem: action.payload.data
			};

        case 'EMBALAGEM_SAIDA_ESTOQUE_LOCAL_SELECT_LISTADO':
			return {
				...state,
				listaEstoqueLocal: action.payload.data
			};

        case 'EMBALAGEM_SAIDA_MOTIVO_SELECT_LISTADO':
			return {
				...state,
				listaMotivo: action.payload.data
			};

        default:
            return state;
    }
}
