import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import Aguardando from '../common/template/aguardando';
import Select from '../common/form/select';
import LabelAndInputMask from '../common/form/labelAndInputMask';

import ReactExport from 'react-export-excel-fixed-xlsx';

import { setFiltro, getLista } from './embalagemViagemRelatorioActions';
import { getLista as getListaCliente } from '../cliente/clienteActions';

class EmbalagemViagemRelatorio extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getLista();
    }

    render() {

		const ExcelFile = ReactExport.ExcelFile;
		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

        return (
            <div>

				<Aguardando aguardando={this.props.aguardando} />

                <Content>
					<ContentCard>
						<ContentCardHeader>
							<Row>
								<Grid cols='6 6 4 2'>
									<Button
										text='Atualizar'
										type={'primary'}
										icon={'fa fa-plus'}
										event={() => {
											this.props.getLista();
										}} />
								</Grid>
								<Grid cols='6 6 4 2'>
									<ExcelFile
										filename={'Viagens'}
										element={
											<Button
												text='Exportar'
												type={'primary'}
												icon={'fa fa-plus'} />
										}>
										<ExcelSheet data={this.props.lista.filter(item => {

											if (this.props.filtro.sentido_fornecedor_cliente && this.props.filtro.sentido_cliente_fornecedor){
												//continue
											} else if (this.props.filtro.sentido_fornecedor_cliente && item.id_origem == 2) {
												//continue
											} else if (this.props.filtro.sentido_cliente_fornecedor && item.id_origem == 1) {
												//continue
											} else {
												return false;
											}

											if (this.props.filtro.id_evento && item.id_ultimo_evento != this.props.filtro.id_evento) {
												return false;
											}

											if (this.props.filtro.id_tipo_embalagem && item.id_tipo_embalagem != this.props.filtro.id_tipo_embalagem) {
												return false;
											}

											if (this.state.pesquisar
												&& !(`${DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem)} ${item.nome_status} ${item.viagem} ${item.solicitacao}
													${item.fatura} ${item.conhecimento_transporte || ''} ${item.carga_manual || ''} ${item.nome_transportadora || ''} ${item.cavalo || ''} ${item.carreta1 || ''} ${item.carreta2 || ''}
													${item.nome_origem}	${item.nome_fornecedor_origem} ${item.nome_destino}	${item.nome_fornecedor_destino} ${item.nome_embalagem} ${item.ultimo_evento}
												`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
												return false;
											}

											return true;
										}).map(item => ({
											...item,
											datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
											datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao),
											quantidade: parseInt(item.quantidade),
											desc_origem: item.nome_fornecedor_origem || item.nome_cliente,
											desc_destino: item.nome_fornecedor_destino || item.nome_cliente
										}))} name='Viagens'>
											<ExcelColumn label='Despacho' value='datahora_viagem' />
											<ExcelColumn label='Recebimento' value='datahora_finalizacao' />
											<ExcelColumn label='Status' value='nome_status' />
											<ExcelColumn label='Viagem' value='viagem' />
											<ExcelColumn label='Solicitacao' value='solicitacao' />
											<ExcelColumn label='Fatura' value='fatura' />
											<ExcelColumn label='Conhec. Transp.' value='conhecimento_transporte' />
											<ExcelColumn label='Embarque GMA' value='carga_manual' />
											<ExcelColumn label='Transportadora' value='nome_transportadora' />
											<ExcelColumn label='Cavalo' value='cavalo' />
											<ExcelColumn label='Carreta 1' value='carreta1' />
											<ExcelColumn label='Carreta 2' value='carreta2' />
											<ExcelColumn label='Origem' value='desc_origem' />
											<ExcelColumn label='Destino' value='desc_destino' />
											<ExcelColumn label='Embalagem' value='nome_embalagem' />
											<ExcelColumn label='Quantidade' value='quantidade' />
											<ExcelColumn label='Chegada Carga' value='chegada_carga' />
											<ExcelColumn label='Início Viagem' value='inicio_viagem' />
											<ExcelColumn label='Chegada Fronteira' value='chegada_fronteira' />
											<ExcelColumn label='Saída Fronteira' value='saida_fronteira' />
											<ExcelColumn label='Chegada Descarga' value='chegada_descarga' />
											<ExcelColumn label='Fim Descarga' value='fim_descarga' />
										</ExcelSheet>
									</ExcelFile>
								</Grid>
							</Row>
						</ContentCardHeader>

						<ContentCardBody>
							<Row>
								<LabelAndInputMask
									label='Data Incial' placeholder='Informe a data'
									cols='12 6 3 2'
									mask='99/99/9999'
									value={this.props.filtro.data_inicial}
									onChange={data => {
										this.props.setFiltro({ ...this.props.filtro, data_inicial: data.target.value });
									}} />

								<LabelAndInputMask
									label='Data Final' placeholder='Informe a data'
									cols='12 6 3 2'
									mask='99/99/9999'
									value={this.props.filtro.data_final}
									onChange={data => {
										this.props.setFiltro({ ...this.props.filtro, data_final: data.target.value });
									}} />

								<Select
									options={this.props.listaEventoTipo}
									label='Evento'
									cols='12 12 4 4'
									placeholder='Selecione o evento'
									value={this.props.filtro.id_evento}
									onChange={data => {
										this.props.setFiltro({ ...this.props.filtro, id_evento: data });
									}} />

								<Select
									options={this.props.listaEmbalagemTipo}
									label='Tipo de Embalagem'
									cols='12 12 3 4'
									placeholder='Selecione o tipo'
									value={this.props.filtro.id_tipo_embalagem}
									onChange={data => {
										this.props.setFiltro({ ...this.props.filtro, id_tipo_embalagem: data });
									}} />
							</Row>
							<Row>
								<LabelAndCheckbox
									name='aberta'
									label='Abertas'
									cols='6 4 3 2'
									value={this.props.filtro.aberta}
									onChange={(data) => {
										this.props.setFiltro({ ...this.props.filtro, aberta: !this.props.filtro.aberta });
										this.props.getLista();
									}} />

								<LabelAndCheckbox
									name='fechada'
									label='Finalizadas'
									cols='6 4 3 2'
									value={this.props.filtro.fechada}
									onChange={(data) => {
										this.props.setFiltro({ ...this.props.filtro, fechada: !this.props.filtro.fechada });
										this.props.getLista();
									}} />

								<LabelAndCheckbox
									name='sentido_fornecedor_cliente'
									label='Fornecedor > Cliente'
									cols='6 4 3 2'
									value={this.props.filtro.sentido_fornecedor_cliente}
									onChange={(data) => {
										this.props.setFiltro({ ...this.props.filtro, sentido_fornecedor_cliente: !this.props.filtro.sentido_fornecedor_cliente });
									}} />

								<LabelAndCheckbox
									name='sentido_cliente_fornecedor'
									label='Cliente > Fornecedor'
									cols='6 4 3 2'
									value={this.props.filtro.sentido_cliente_fornecedor}
									onChange={(data) => {
										this.props.setFiltro({ ...this.props.filtro, sentido_cliente_fornecedor: !this.props.filtro.sentido_cliente_fornecedor });
									}} />

							</Row>

							<input className='form-control'
				                 placeholder='Pesquisar'
				                 type='text'
								 value={this.state.pesquisar}
								 onChange={e => {
									 this.setState({ ...this.state, pesquisar: e.target.value });
								 }} />

							<Table responsive>
								<THead>
									<Tr>
										<Th>Despacho</Th>
										<Th>Recebimento</Th>
										<Th>Status</Th>
										<Th>Viagem</Th>
										<Th>Solicitação</Th>
										<Th>Fatura</Th>
										<Th>Conhec. Transp.</Th>
										<Th>Embarque GMA</Th>
										<Th>Transp.</Th>
										<Th>Cavalo</Th>
										<Th>Carretas</Th>
										<Th>Origem</Th>
										<Th>Destino</Th>
										<Th>Embalagem</Th>
										<Th alignRight>Quantidade</Th>
										<Th>Ult. Status</Th>
									</Tr>
								</THead>
								<TBody>
									{(this.props.lista).filter(item => {

										if (this.props.filtro.sentido_fornecedor_cliente && this.props.filtro.sentido_cliente_fornecedor){
											//continue
										} else if (this.props.filtro.sentido_fornecedor_cliente && item.id_origem == 2) {
											//continue
										} else if (this.props.filtro.sentido_cliente_fornecedor && item.id_origem == 1) {
											//continue
										} else {
											return false;
										}

										if (this.props.filtro.id_evento && item.id_ultimo_evento != this.props.filtro.id_evento) {
											return false;
										}

										if (this.props.filtro.id_tipo_embalagem && item.id_tipo_embalagem != this.props.filtro.id_tipo_embalagem) {
											return false;
										}

										if (this.state.pesquisar
											&& !(`${DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem)} ${item.nome_status} ${item.viagem} ${item.solicitacao}
												${item.fatura} ${item.conhecimento_transporte || ''} ${item.carga_manual || ''} ${item.nome_transportadora || ''} ${item.cavalo || ''} ${item.carreta1 || ''} ${item.carreta2 || ''}
												${item.nome_origem}	${item.nome_fornecedor_origem} ${item.nome_destino}	${item.nome_fornecedor_destino} ${item.nome_embalagem} ${item.ultimo_evento}
											`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {

											return false;
										}
										return true;
									}).map((item, i) => (
										<Tr key={i} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
											onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
											onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
											<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem)}</Td>
											<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao)}</Td>
											<Td>{item.nome_status}</Td>
											<Td>{item.viagem}</Td>
											<Td>{item.solicitacao}</Td>
											<Td>{item.fatura}</Td>
											<Td>{item.conhecimento_transporte || ''}</Td>
											<Td>{item.carga_manual || ''}</Td>
											<Td>{item.nome_transportadora || ''}</Td>
											<Td>{item.cavalo || ''}</Td>
											<Td>{item.carreta1 || ''}{item.carreta2 ? ', ' : ''}{item.carreta2 || ''}</Td>
											<Td>{item.nome_fornecedor_origem || item.nome_cliente}</Td>
											<Td>{item.nome_fornecedor_destino || item.nome_cliente}</Td>
											<Td>{item.nome_embalagem}</Td>
											<Td alignRight>{parseInt(item.quantidade)}</Td>
											<Td>{item.ultimo_evento}</Td>
										</Tr>
									))}
								</TBody>
							</Table>
						</ContentCardBody>
					</ContentCard>
				</Content>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	filtro: state.embalagemViagemRelatorio.filtro,
	filtro: state.embalagemViagemRelatorio.filtro,
	aguardando: state.embalagemViagemRelatorio.aguardando,
	lista: state.embalagemViagemRelatorio.lista,
	listaEventoTipo: state.embalagemViagemRelatorio.listaEventoTipo,
	listaEmbalagemTipo: state.embalagem.listaTipo
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setFiltro, getLista
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EmbalagemViagemRelatorio);
