import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';

export function setFiltro(filtro = {}) {
    return {
        type: 'EMBALAGEM_VIAGEM_RELATORIO_SALDO_FILTRO',
        payload: {
			filtro: filtro
		}
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'EMBALAGEM_VIAGEM_RELATORIO_SALDO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function getLista() {
	return (dispatch, getState) => {
		dispatch(setAguardando(true));
    	axios.get(`${consts.API_URL}/embalagemMovimento/listarelatorioSaldo?id_cliente=${getState().auth.usuario.id_cliente || 0}
			&id_transportadora=${getState().auth.usuario.id_transportadora || null}
			&id_fornecedor=${getState().auth.usuario.id_fornecedor || null}`)
		.then(resp => {
			dispatch({
		        type: 'EMBALAGEM_VIAGEM_RELATORIO_SALDO_LISTADO',
		        payload: resp
		    });
			dispatch(setAguardando(false));
		}).catch(e => {
			setErroAPI(e);
			dispatch(setAguardando(false));
		});
	}
}

export function getListaViagem() {
	return (dispatch, getState) => {
		if (getState().auth.usuario) {
	    	axios.get(`${consts.API_URL}/embalagemViagem/listarRelatorioGeral?
				id_cliente=${getState().auth.usuario.id_cliente || 0}
				&id_transportadora=${getState().auth.usuario.id_transportadora || null}
				&aberta=true
				&fechada=false
			`).then(resp => {
				dispatch({
			        type: 'DASHBOARD_EMBALAGEM_VIAGEM_RELATORIO_SALDO_VIAGEM_LISTADO',
			        payload: resp
			    });
			}).catch(e => {
				setErroAPI(e);
			});
		}
	}
}
