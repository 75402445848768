import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './embalagemViagemForm';
import DateFormat from '../common/dateFormat/DateFormat';
import LabelAndCheckbox from '../common/form/labelAndCheckbox';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Aguardando from '../common/template/aguardando';

import {
	setModoTela, setFiltro, initForm, salvar, excluir, getListaComprovante, finalizarComprovada, reabrirComprovada, gerarComprovanteEntrega,
	setModoTelaItem, initFormItem, salvarItem, excluirItem, getListaItem, getListaHistorico,
	getListaFornecedor, getListaEmbalagem, getListaEstoqueLocal, getListaSentido, getListaStatus, getListaTransportadora, getListaEmbalagemViagemEventoTipo } from './embalagemViagemActions';
import { getLista as getListaCliente } from '../cliente/clienteActions';

class EmbalagemViagemComprovante extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null
	}

    componentWillMount() {
        this.props.getListaComprovante();
		this.props.getListaTransportadora();
		this.props.getListaCliente();
		this.props.getListaFornecedor();
		this.props.getListaEmbalagem();
		this.props.getListaEstoqueLocal();
		this.props.getListaSentido();
		this.props.getListaStatus();
		this.props.getListaEmbalagemViagemEventoTipo();
    }

    render() {
        return (
            <div>
                <Content>

					<Aguardando aguardando={this.props.aguardando} />
					
					{this.props.modoTela == 'visualizacao' ? (
						<Form onSubmit={() => {}} visualizacao />
					) : null}
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'comprovantes' ? (
						<Form onSubmit={this.props.salvar} comprovantes />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader>
					<Row>
						<Grid cols='6 6 4 2'>
							<Button
								text='Atualizar'
								type={'primary'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.getListaComprovante();
								}} />
						</Grid>
					</Row>
				</ContentCardHeader>
				<ContentCardBody>
					<Row>
						<LabelAndInputMask
							label='Data/Hora' placeholder='Informe o período inicial'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_inicial_comprovante}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_inicial_comprovante: data.target.value });
								this.props.getListaComprovante();
							}} />

						<LabelAndInputMask
							label='Data/Hora' placeholder='Informe o período final'
							cols='12 6 3 2'
							mask='99/99/9999'
							value={this.props.filtro.data_final_comprovante}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, data_final_comprovante: data.target.value });
								this.props.getListaComprovante();
							}} />
					</Row>
					<Row>
						<LabelAndCheckbox
							name='sem_comprovante'
							label='Sem Comprovantes'
							cols='6 4 3 2'
							value={this.props.filtro.sem_comprovante}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, sem_comprovante: !this.props.filtro.sem_comprovante });
								// this.props.getListaComprovante();
							}} />

						<LabelAndCheckbox
							name='comprovada'
							label='Comprovadas'
							cols='6 4 3 2'
							value={this.props.filtro.comprovada}
							onChange={(data) => {
								this.props.setFiltro({ ...this.props.filtro, comprovada: !this.props.filtro.comprovada });
								// this.props.getListaComprovante();
							}} />

					</Row>

					<input className='form-control'
						 placeholder='Pesquisar'
						 type='text'
						 value={this.state.pesquisar}
						 onChange={e => {
							 this.setState({ ...this.state, pesquisar: e.target.value });
						 }} />

					<Table responsive>
						<THead>
							<Tr>
								<Th>Data/Hora</Th>
								<Th>Transportadora</Th>
								<Th>Status</Th>
								<Th>Viagem</Th>
								<Th>Conhec. Transp.</Th>
								<Th>Cavalo</Th>
								<Th>Carretas</Th>
								<Th>Origem</Th>
								<Th>Destino</Th>
								<Th>Último Status</Th>
								<Th alignCenter>Comprovante</Th>
								<Th width={250}></Th>
							</Tr>
						</THead>
						<TBody>
							{(this.props.lista).filter(item => {
								if (this.state.pesquisar
									&& !(`${DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem)} ${item.nome_transportadora} ${item.nome_status} ${item.viagem} ${item.solicitacao}
										${item.fatura} ${item.conhecimento_transporte || ''} ${item.cavalo || ''} ${item.carreta1 || ''} ${item.carreta2 || ''}
										${item.nome_origem}	${item.nome_fornecedor_origem} ${item.nome_destino}	${item.nome_fornecedor_destino} ${(item.pdf_fechamento || []).length > 0 ? 'Sim' : 'Não'}
									`).toUpperCase().includes(this.state.pesquisar.toUpperCase())) {
									return false;
								}
								if (this.props.filtro.sem_comprovante && this.props.filtro.comprovada) {
									return true;
								} else if (this.props.filtro.sem_comprovante) {
									return (item.pdf_fechamento || []).length <= 0
								} else if (this.props.filtro.comprovada) {
									return (item.pdf_fechamento || []).length > 0
								} else {
									return false;
								}
								return true;
							}).map((item, i) => (
								<Tr key={i} className={`${this.state.linhaSelecionada == item.id ? 'hover' : ''}`}
									onClick={() => this.setState({ ...this.state, linhaSelecionada: item.id })}
									onMouseEnter={() => this.setState({ ...this.state, linhaSelecionada: item.id })} >
									<Td>{DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem)}</Td>
									<Td>{item.nome_transportadora}</Td>
									<Td>{item.ativo ? item.nome_status : 'Cancelada'}</Td>
									<Td>{item.viagem}</Td>
									<Td>{item.conhecimento_transporte || ''}</Td>
									<Td>{item.cavalo || ''}</Td>
									<Td>{item.carreta1 || ''}{item.carreta2 ? ', ' : ''}{item.carreta2 || ''}</Td>
									<Td>{item.nome_fornecedor_origem || item.nome_cliente}</Td>
									<Td>{item.nome_fornecedor_destino || item.nome_cliente}</Td>
									<Td>{item.ultimo_evento || ''}</Td>
									<Td alignCenter>{(item.pdf_fechamento || []).length > 0 ? 'Sim' : 'Não'}</Td>
									<Td alignRight minWidth={250}>
										<ButtonTable
											type={'primary'}
											icon={'fas fa-file-medical'}
											disabled={!item.ativo}
											visible={this.state.linhaSelecionada == item.id}
											title='Editar Comprovantes'
											event={() => {
												this.props.setModoTela('comprovantes', {
													...item,
													datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao)
												});
												this.props.initForm({
													...item,
													datahora_viagem: DateFormat.formatarDataHoraSqlParaTela(item.datahora_viagem),
													datahora_finalizacao: DateFormat.formatarDataHoraSqlParaTela(item.datahora_finalizacao)
												});
											}} />

										{this.props.sessao && !this.props.sessao.modoCliente && !this.props.sessao.modoTransportadora && item.id_status != 1 ? (
											<ButtonTable
												type={'success'}
												icon={`fas fa-${item.id_status != 3 ? 'check' : 'undo'}`}
												visible={this.state.linhaSelecionada == item.id}
												title={item.id_status != 3 ? 'Comprovada' : 'Reabrir viagem'}
												event={() => {
													if (item.id_status != 3) {
														this.props.finalizarComprovada(item);
													} else if (item.id_status == 3) {
														this.props.reabrirComprovada(item);
													}
												}} />
										) : null}
									</Td>
								</Tr>
							))}
						</TBody>
					</Table>
				</ContentCardBody>
			</ContentCard>
		);
	}
}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.embalagemViagem.modoTela,
	aguardando: state.embalagemViagem.aguardando,
	filtro: state.embalagemViagem.filtro,
	lista: state.embalagemViagem.listaComprovante,
	listaItem: state.embalagemViagem.listaItem,
	listaFornecedor: state.embalagemViagem.listaFornecedor,
	listaEmbalagem: state.embalagemViagem.listaEmbalagem,
	listaEstoqueLocal: state.embalagemViagem.listaEstoqueLocal
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, setFiltro, initForm, salvar, excluir, getListaComprovante, finalizarComprovada, reabrirComprovada, gerarComprovanteEntrega,
	setModoTelaItem, initFormItem, salvarItem, excluirItem, getListaItem, getListaHistorico,
	getListaFornecedor, getListaEmbalagem, getListaEstoqueLocal, getListaSentido, getListaStatus, getListaCliente, getListaTransportadora, getListaEmbalagemViagemEventoTipo
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EmbalagemViagemComprovante);
